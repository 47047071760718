import React, { useState } from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// core components
import styles from './../../assets/jss/material-dashboard-react/components/tableStyle.js';
import TablePagination from '@material-ui/core/TablePagination';
import EmptyState from '../EmptyState';
import Lang from '../../variables/i18n';

const useStyles = makeStyles(styles);

export default function CustomTable({
	tableHead = [],
	tableData = [],
	tableHeaderColor,
	totalRows,
	pending = true,
	error,
	empty,
	rowsPerPage = 10,
	...props
}) {
	const classes = useStyles();
	const [currentPage, setCurrentPage] = useState(0);
	const [rPerPage, setRPerPage] = useState(rowsPerPage);
	const rowsTotal = totalRows || tableData.length;
	const firstRow = Math.min(currentPage * rowsPerPage, tableData.length);
	const lastRow = Math.min(firstRow + rPerPage);

	return (
		<div className={classes.tableResponsive}>
			<Table className={classes.table}>
				{tableHead !== undefined ? (
					<TableHead className={classes[tableHeaderColor + 'TableHeader']}>
						<TableRow className={classes.tableHeadRow}>
							{tableHead.map((prop, key) => {
								return (
									<TableCell
										className={classes.tableCell + ' ' + classes.tableHeadCell}
										key={key}
									>
										{prop}
									</TableCell>
								);
							})}
						</TableRow>
					</TableHead>
				) : null}
				<TableBody>
					{props.children
						? { ...props.children }
						: tableData.slice(firstRow, lastRow).map((prop, key) => {
								return (
									<TableRow key={key} className={classes.tableBodyRow}>
										{prop.map((prop, key) => {
											return (
												<TableCell className={classes.tableCell} key={key}>
													{prop}
												</TableCell>
											);
										})}
									</TableRow>
								);
						  })}
				</TableBody>
			</Table>
			<>
				{!empty && (
					<TablePagination
						component="div"
						count={rowsTotal}
						page={currentPage}
						onPageChange={(e, newPage) => {
							console.log(`new page: ${newPage}`);
							setCurrentPage(newPage);
						}}
						rowsPerPage={rPerPage}
						onRowsPerPageChange={({ target }) => setRPerPage(target.value)}
						labelRowsPerPage={Lang.rowsPerPage}
						backIconButtonText={Lang.previous}
						nextIconButtonText={Lang.next}
						labelDisplayedRows={({ from, to, count, page }) =>
							`Σελίδα ${page + 1}: σειρές ${from} εως και ${to}, απο ${count}`
						}
					/>
				)}
				<EmptyState pending={pending} error={error} empty={empty} />
			</>
		</div>
	);
}

CustomTable.defaultProps = {
	tableHeaderColor: 'gray',
};

CustomTable.propTypes = {
	tableHeaderColor: PropTypes.oneOf([
		'warning',
		'primary',
		'danger',
		'success',
		'info',
		'rose',
		'gray',
	]),
	tableHead: PropTypes.arrayOf(PropTypes.string),
	tableData: PropTypes.arrayOf(
		PropTypes.arrayOf(
			PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.object,
				PropTypes.number,
			])
		)
	),
};
