export const firebaseConfig = {
	apiKey: 'AIzaSyB-pWI4ataPLzguBlrW6dhkijK12zD-M_k',
	authDomain: 'fotomada-dev.firebaseapp.com',
	databaseURL: 'https://fotomada-dev.firebaseio.com',
	projectId: 'fotomada-dev',
	storageBucket: 'fotomada-dev.appspot.com',
	messagingSenderId: '564229656022',
	appId: '1:564229656022:web:9ecf78a3ef1b639df12877',
};

