import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import styles from '../../assets/jss/material-dashboard-react/views/dashboardStyle';
import { SCModules } from '../supercore/api';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import { Euro } from '@material-ui/icons';
import Lang from '../../variables/i18n';
import CircularProgress from '@material-ui/core/CircularProgress';
import { generateOnSettled, useQueryDocument } from '../ReactQueryUtils';

const useStyles = makeStyles(styles);

export default function VaultWidget({ shift, month }) {
	const classes = useStyles();
	const [pendingState, setPending] = useState(false);
	const now = new Date();
	const year = now.getFullYear();
	const thisMonth = now.getMonth() + 1;
	const day = now.getDate();
	const shiftId = month
		? `${year}-${thisMonth}`
		: `${year}-${thisMonth}-${day}-${shift}`;

	const [data, isLoading] = useQueryDocument(
		SCModules.vaultTotals,
		shiftId,
		generateOnSettled(undefined, setPending, undefined),
		{
			refetchOnMount: true,
			refetchOnReconnect: true,
			refetchOnWindowFocus: true,
		}
	);
	const pending = pendingState || isLoading;

	return (
		<Card>
			<CardHeader color="primary" stats icon>
				<CardIcon color="info">
					<Euro />
				</CardIcon>
				<p className={classes.cardCategory}>
					{shift ? `Σήμερα - Βάρδια ${shift}` : 'Μήνας'}
				</p>
				<h3 className={classes.cardTitle}>
					{pending ? (
						<CircularProgress className="vault-widget-progress" />
					) : (
						<>
							<small>{Lang.currencySymbol.euro}</small>
							{data.amountTotal || 0}
						</>
					)}
				</h3>
			</CardHeader>
		</Card>
	);
}
