import React, { useContext, useEffect, useState } from 'react';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';

import routes from './../routes.js';
import styles from './../assets/jss/material-dashboard-react/layouts/adminStyle.js';

import { Card } from '@material-ui/core';
import CardHeader from '../components/Card/CardHeader';
import CardBody from '../components/Card/CardBody';
import CustomInput from '../components/CustomInput/CustomInput';
import AnonNavbar from '../components/Navbars/AnonNavbar';
import Button from './../components/CustomButtons/Button.js';
import CardFooter from '../components/Card/CardFooter';

import EmailSharp from '@material-ui/icons/EmailSharp';
import Lock from '@material-ui/icons/Lock';
import ArrowForward from '@material-ui/icons/ArrowForward';
import PersonAdd from '@material-ui/icons/PersonAdd';
import { AppContext } from '../store/store';
import LayoutNotification from './utils';
import Lang from '../variables/i18n';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import { useLocation, useNavigate } from 'react-router';
import SystemSettingsDefaults from '../variables/sysSettings';
import CustomFormControl from '../components/CustomFormControl';
import Ntf from '../components/Ntf';

const useStyles = makeStyles(styles);

export default function Login({ ...rest }) {
	const classes = useStyles();
	const navigate = useNavigate();
	const { state } = useLocation();
	const [ntfTxt, setNtfTxt] = useState();
	const { firebase, user, setUser } = useContext(AppContext);
	const [showPending, setShowPending] = useState(false);
	const [loginEmailValid, setLoginEmailValid] = useState(true);
	const [loginPassValid, setLoginPassValid] = useState(true);

	const [form, setForm] = useState({ userEmail: '', userPassword: '' });
	const { userEmail, userPassword } = form;

	const handleSignIn = async (event) => {
		event.preventDefault();
		setNtfTxt(undefined);
		setShowPending(true);
		setLoginPassValid(true);
		setLoginEmailValid(true);

		try {
			const response = await firebase.signInUser(userEmail, userPassword);
			setUser(response);
		} catch (error) {
			const { code } = error;
			console.log(code);

			code === 'auth/wrong-password' && setLoginPassValid(false);
			code === 'auth/user-not-found' && setLoginEmailValid(false);
			code === 'auth/too-many-requests' &&
				(setLoginEmailValid(false) || setLoginPassValid(false));
			setNtfTxt(Lang.firebaseErrors[code] || Lang.firebaseErrors.genericError);
		} finally {
			setShowPending(false);
		}
	};

	useEffect(() => {
		let mounted = true;
		if (user) {
			const { from } = state || { from: { pathname: '/' } };
			const to = from.pathname === '/resetPassword' ? '/' : from.pathname;
			mounted &&
				navigate(to, { replace: true, state: { ...state, from: '/login' } });
		}
		return () => {
			mounted = false;
		};
	}, [user, state, navigate]);

	return (
		<div className={classes.wrapper}>
			<div className={classes.mainPanel + ' unauthContainer'}>
				<AnonNavbar routes={routes} {...rest} />
				<div className={classes.content}>
					<LayoutNotification />

					<Card className={classes.registerCard}>
						<CardHeader>
							<h2>{Lang.login.header}</h2>
						</CardHeader>
						<CardBody>
							<Ntf
								messages={ntfTxt}
								close
								onClose={() => setNtfTxt(undefined)}
							/>
							<form>
								<CustomFormControl>
									<CustomInput
										error={!loginEmailValid}
										inputProps={{
											id: 'userEmail',
											'data-testid': 'userEmail',
											type: 'email',
											disabled: showPending,
											placeholder: 'you@example.com',
											value: userEmail,
											autoComplete: 'username',
											'aria-label': Lang.login.email,
											onInput: (e) =>
												setForm({ ...form, userEmail: e.target.value }),
											startAdornment: (
												<InputAdornment position="start">
													<EmailSharp />
												</InputAdornment>
											),
										}}
									/>

									<CustomInput
										error={!loginPassValid}
										inputProps={{
											id: 'userPassword',
											'data-testid': 'userPassword',
											type: 'password',
											disabled: showPending,
											placeholder: Lang.login.password,
											value: userPassword,
											autoComplete: 'current-password',
											'aria-label': Lang.login.password,
											onInput: (e) =>
												setForm({ ...form, userPassword: e.target.value }),
											startAdornment: (
												<InputAdornment position="start">
													<Lock />
												</InputAdornment>
											),
										}}
									/>
								</CustomFormControl>
							</form>
						</CardBody>
						<CardFooter>
							{SystemSettingsDefaults.allowSelfSignup ? (
								<Button
									disabled={showPending}
									onClick={() => navigate('/register')}
								>
									<PersonAdd />
									<span className="btnTextIconSpacer">
										{Lang.login.createAccount}
									</span>
								</Button>
							) : (
								<span />
							)}
							<Tooltip title={Lang.login.login}>
								<span>
									<Fab
										aria-label="login"
										onClick={(e) => handleSignIn(e)}
										disabled={showPending}
									>
										{showPending ? (
											<CircularProgress size={48} />
										) : (
											<ArrowForward />
										)}
									</Fab>
								</span>
							</Tooltip>
						</CardFooter>
					</Card>
				</div>
			</div>
		</div>
	);
}
