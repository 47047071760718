import FormLabel from '@material-ui/core/FormLabel';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CustomFormControl from '../CustomFormControl';
import Lang from '../../variables/i18n';
import { TimePickerEl } from '../DatePicker';
import CustomRadioGroup from '../CustomRadioGroup';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import UserPicker from '../UserPicker';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CustomInput from '../CustomInput/CustomInput';
import React from 'react';
import PropTypes from 'prop-types';
import { Dimension } from '../../variables/general';
import { makeStyles } from '@material-ui/core/styles';
import { customInputTableStyle } from '../../assets/jss/material-dashboard-react/components/customInputStyle';
import SchoolSelect from '../SchoolSelect';
import EventSelect from '../EventSelect';
import TaskCheckbox from '../TaskCheckbox';

const useStyles = makeStyles(customInputTableStyle);

export const FormItemTypes = {
	text: 'text',
	number: 'number',
	email: 'email',
	select: 'select',
	textarea: 'textarea',
	taskcheckbox: 'taskcheckbox',
	password: 'password',
	checkbox: 'checkbox',
	radio: 'radio',
	datepicker: 'datepicker',
	timepicker: 'timepicker',
	formLabel: 'formLabel',
	userpicker: 'userpicker',
	renderElement: 'renderElement',
	schoolSelect: 'schoolSelect',
	eventSelect: 'eventSelect',
};

const FormItem = ({
	id,
	label,
	type = 'text',
	error = false,
	required = false,
	display = true,
	readOnly,
	disabled,
	placeholder,
	value,
	options,
	// defaultOption,
	defaultSelectLabel = 'Επιλογή...',
	renderElement,
	multiline,
	rows,
	shrink,
	//update handlers
	handleTimeUpdate,
	handleNumberUpdate,
	handleFormUpdate,
	handleToggleCheckbox,
	// handleSelectUpdate,
	...props
}) => {
	const classes = useStyles();

	if (typeof renderElement === 'function') {
		return renderElement();
	}

	label = required ? (
		<>
			<span style={{ color: 'red' }}>*</span> {label}
		</>
	) : (
		label
	);

	if (type === FormItemTypes.select && !options) {
		throw new Error(
			`Element with ID '${id}' is type '${FormItemTypes.select}' but has no options`
		);
	}

	switch (type) {
		case 'renderElement':
			return renderElement;
		case 'formLabel':
			return <FormLabel>{label}</FormLabel>;
		case 'eventSelect':
			return (
				<EventSelect
					id={id}
					value={value}
					onChange={(e) => handleFormUpdate(id, e)}
				/>
			);
		case 'schoolSelect':
			return (
				<SchoolSelect
					onChange={(e, val) =>
						handleFormUpdate(id, { target: { value: val } })
					}
					id={id}
					labelText={label}
					required={required}
					disabled={disabled}
				/>
			);
		case 'datepicker':
			return (
				<KeyboardDatePicker
					// required={required}
					format="dd/MM/yyyy"
					className={classes.formControl}
					id={id}
					label={label || 'Ημερομηνία'}
					onChange={handleTimeUpdate}
					value={value || new Date()}
					readOnly={readOnly}
					disabled={disabled}
					animateYearScrolling
					autoOk
				/>
			);
		case 'timepicker':
			return (
				<CustomFormControl
					required={required}
					error={error}
					htmlFor={id}
					label={label || Lang.time}
				>
					<TimePickerEl
						id={id}
						showTodayButton
						todayLabel="Τωρα"
						margin="normal"
						minutesStep={5}
						value={value || ''}
						onChange={handleTimeUpdate}
					/>
				</CustomFormControl>
			);
		case 'radio':
			return (
				<CustomRadioGroup
					error={error}
					required={required}
					onChange={handleFormUpdate}
					options={options}
					label={label}
				/>
			);
		case 'checkbox':
			return (
				<FormControlLabel
					label={label}
					control={
						<Checkbox
							required={required}
							id={id}
							onChange={handleToggleCheckbox}
							checked={value || false}
						/>
					}
				/>
			);
		case 'userpicker':
			return (
				<UserPicker
					id={id}
					onChange={handleFormUpdate}
					label={label}
					value={value || ''}
					{...props}
				/>
			);
		case FormItemTypes.taskcheckbox:
			return (
				<TaskCheckbox
					id={id}
					label={label}
					onChange={handleToggleCheckbox}
					checked={!!value}
				/>
			);
		case FormItemTypes.select:
			return (
				<CustomFormControl error={error} label={label} htmlFor={id}>
					<Select
						id={id}
						data-testid={id}
						value={
							value ||
							(options || []).filter(({ selected }) => selected).pop() ||
							''
						}
						onChange={handleFormUpdate}
					>
						<MenuItem value={false} selected={!value} disabled>
							{defaultSelectLabel}
						</MenuItem>
						{options.map(
							({ label, value: optionValue, optionDisabled = false }) => (
								<MenuItem
									key={`${id}_${optionValue}${optionDisabled}`}
									value={optionValue}
									selected={optionValue === value}
									disabled={optionDisabled}
								>
									{label}
								</MenuItem>
							)
						)}
					</Select>
				</CustomFormControl>
			);
		case 'number':
			return (
				<CustomInput
					labelText={label}
					type={type}
					onChange={handleNumberUpdate}
					error={error}
					required={required}
					formControlProps={{
						fullWidth: true,
					}}
					labelProps={{ shrink: shrink || `${value}` !== '' }}
					inputProps={{
						id,
						'data-testid': id,
						type,
						value,
						readOnly,
						disabled,
						placeholder,
						multiline: false,
					}}
				/>
			);

		default:
			return (
				<CustomInput
					labelText={label}
					type={type}
					onChange={handleFormUpdate}
					error={error}
					required={required}
					formControlProps={{
						fullWidth: true,
					}}
					labelProps={{
						shrink: shrink || !!(value && value.length),
					}}
					inputProps={{
						id,
						'data-testid': id,
						type,
						value: value || '',
						readOnly,
						disabled,
						placeholder,
						multiline:
							multiline === undefined ? type === 'textarea' : multiline,
						rows: rows || type === 'textarea' ? 3 : 1,
					}}
				/>
			);
	}
};

FormItem.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	onUpdate: PropTypes.func,
	display: PropTypes.bool,
	renderElement: PropTypes.func,
	error: PropTypes.bool,
	multiline: PropTypes.bool,
	required: PropTypes.bool,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	fullWidth: PropTypes.bool,
	size: PropTypes.oneOf(Object.keys(Dimension)),
	rows: PropTypes.number,
	options: PropTypes.array,
	yup: PropTypes.object,
	type: PropTypes.oneOf(Object.keys(FormItemTypes)),
};

export default FormItem;
