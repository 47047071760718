import React, { Component, createContext, useReducer } from 'react';
import firebase from 'firebase';
import deepmerge from 'deepmerge';
import { SCModules } from '../components/supercore/api';
import { fromArray } from '../components/useSupercore';

export const AppContext = createContext(null);
export const UserContext = createContext({ user: null });

class UserProvider extends Component {
	state = {
		user: null,
	};

	componentDidMount = () => {
		firebase.auth.onAuthStateChanged((userAuth) => {
			this.setState({ user: userAuth });
		});
	};

	render() {
		return (
			<UserContext.Provider value={this.state.user}>
				{this.props.children}
			</UserContext.Provider>
		);
	}
}

export const Actions = {
	/**
	 * Clear all entries
	 */
	INIT_STORE: 'INIT_STORE',
	//Generic
	/**
	 * Update single entry in this collection
	 */
	UPDATE_SINGLE: 'UPDATE_SINGLE',
	UPDATE_USER: 'UPDATE_USER',
	UPDATE_HISTORY: 'UPDATE_HISTORY',
	/**
	 * Update multiple entries in this collection
	 */
	UPDATE_PAGE: 'UPDATE_PAGE',
	UPDATE_USER_PAGE: 'UPDATE_USER_PAGE',
	/**
	 * Delete entry in this collection
	 */
	DELETE: 'DELETE',

	//Forms
	/**
	 * Update field in form. Requires `fieldId`
	 */
	UPDATE_FORM: 'UPDATE_FORM',
	/**
	 * Clear form
	 */
	CLEAR_FORM: 'CLEAR_FORM',
	/**
	 * Set all fields of a form
	 */
	SET_FORM: 'SET_FORM',
};

function reducer(state, action) {
	const { type, data, collection, fieldId, id } = action;
	if (!collection && type !== Actions.INIT_STORE) {
		throw new Error('Missing field in action data: collection');
	}
	const debug = false;
	let newState = state;

	switch (type) {
		case Actions.INIT_STORE:
			newState = {};
			break;
		case Actions.UPDATE_USER:
			if (!data.uid) {
				console.warn(`Missing uid field from UPDATE_USER action`);
				break;
			}
			newState = deepmerge(state, { [SCModules.users]: { [data.uid]: data } });
			break;
		case Actions.UPDATE_HISTORY:
			if (!Array.isArray(data) || !data.length) {
				console.warn(
					`${collection} ${type}: data was expected to be array, is of type ${typeof data}`
				);
				break;
			}
			newState = deepmerge(state, {
				[collection]: fromArray(data, 'actionId'),
			});
			break;
		case Actions.UPDATE_PAGE:
			if (!Array.isArray(data) || !data.length) {
				console.warn(
					`${collection} ${type}: data was expected to be array, is of type ${typeof data}`
				);
				break;
			}
			newState = deepmerge(state, { [collection]: fromArray(data) });
			break;
		case Actions.UPDATE_USER_PAGE:
			if (!Array.isArray(data) || !data.length) {
				console.warn(
					`${collection} ${type}: data was expected to be array, is of type ${typeof data}`
				);
				break;
			}
			newState = deepmerge(state, { [collection]: fromArray(data, 'uid') });
			break;
		case Actions.UPDATE_SINGLE:
			if (!id) {
				console.warn(`${collection} ${type}: Missing ID, skipping`);
				break;
			}

			if (collection === SCModules.users) {
				throw new Error(`${collection} ${type}: Using wrong action type`);
			}

			newState = deepmerge(state, { [collection]: { [id]: data } });
			break;
		case Actions.DELETE:
			if (!id) {
				throw new Error('Missing field in action data: id');
			}
			delete state[collection][id];
			newState = { ...state };
			break;

		case Actions.CLEAR_FORM:
			delete state.form[collection];
			return { ...state };
		case Actions.UPDATE_FORM:
			if (!fieldId) {
				throw new Error('Missing field in action data: fieldId');
			}
			newState = {
				...state,
				form: {
					...state.form,
					[collection]: { ...(state.form[collection] || {}), [fieldId]: data },
				},
			};
			break;
		// return newState;
		case Actions.SET_FORM:
			newState = { ...state, form: { ...state.form, [collection]: data } };
			break;
		default:
			newState = { ...state };
	}

	if (debug) {
		console.group(`Dispatch ${type} on ${collection}`);
		console.log('incoming data:');
		console.log(data);
		console.log('state');
		console.log(state);
		console.log('newState');
		console.log(newState);
		console.groupEnd();
	}

	return newState;
}

export default UserProvider;

export const useInitialiseStore = () => {
	const [state, dispatch] = useReducer(reducer, {});

	return { state, dispatch };
};
