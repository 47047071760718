import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// material-ui components
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// core components
import Card from './../../components/Card/Card.js';
import CardBody from './../../components/Card/CardBody.js';
import CardHeader from './../../components/Card/CardHeader.js';

import styles from './../../assets/jss/material-dashboard-react/components/customTabsStyle.js';
import CardFooter from '../Card/CardFooter';

const useStyles = makeStyles(styles);

export default function CustomTabs({
	headerColor,
	plainTabs,
	tabs,
	title,
	rtlActive,
	cardFooter,
}) {
	const [selectedTab, setSelectedTab] = React.useState(
		tabs
			.map(({ tabActive }, i) => (tabActive ? i : 0))
			.reduce((acc, curr) => Math.max(acc, curr), 0)
	);
	const handleChange = (event, value) => {
		setSelectedTab(value);
	};
	const classes = useStyles();
	const cardTitle = classNames({
		[classes.cardTitle]: true,
		[classes.cardTitleRTL]: rtlActive,
	});
	return (
		<Card plain={plainTabs}>
			<CardHeader color={headerColor} plain={plainTabs}>
				{title !== undefined ? <div className={cardTitle}>{title}</div> : null}
				<Tabs
					value={selectedTab}
					onChange={handleChange}
					classes={{
						root: classes.tabsRoot,
						indicator: classes.displayNone,
						scrollButtons: classes.displayNone,
					}}
					variant="scrollable"
					scrollButtons="auto"
				>
					{tabs.map((prop, key) => {
						var icon = {};
						if (prop.tabIcon) {
							icon = {
								icon: <prop.tabIcon />,
							};
						}
						return (
							<Tab
								classes={{
									root: classes.tabRootButton,
									selected: classes.tabSelected,
									wrapper: classes.tabWrapper,
								}}
								key={key}
								label={prop.tabName}
								{...icon}
							/>
						);
					})}
				</Tabs>
			</CardHeader>
			<CardBody>
				{tabs
					.filter((prop, key) => key === selectedTab)
					.map((prop, key) => (
						<div key={key}>{prop.tabContent}</div>
					))}
			</CardBody>
			{cardFooter && <CardFooter>{cardFooter}</CardFooter>}
		</Card>
	);
}

CustomTabs.propTypes = {
	headerColor: PropTypes.oneOf([
		'warning',
		'success',
		'danger',
		'info',
		'primary',
		'rose',
	]),
	title: PropTypes.string,
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			tabName: PropTypes.string.isRequired,
			tabIcon: PropTypes.object,
			tabContent: PropTypes.node.isRequired,
			tabActive: PropTypes.bool,
		})
	),
	rtlActive: PropTypes.bool,
	plainTabs: PropTypes.bool,
};
