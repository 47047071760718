import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './../../assets/jss/material-dashboard-react/components/headerLinksStyle.js';
import { Actions, AppContext } from '../../store/store';
import ExpandableWidget from './ExpandableWidget';
import useCurrentUser from '../Firebase/Firebase';
import UserAvatar from '../UserAvatar';
import { useStore } from '../useSupercore';
import { SCModules } from '../supercore/api';

const useStyles = makeStyles(styles);

export default function ProfileWidget() {
	const classes = useStyles();

	const { firebase } = useContext(AppContext);
	const [user] = useCurrentUser();
	const { dispatch } = useStore(SCModules.users);

	const logoutHandler = async () => {
		dispatch({ type: Actions.INIT_STORE });
		await firebase.signOutUser();
	};

	return (
		<ExpandableWidget
			label="Profile"
			id="profile-menu-list-grow"
			icon={<UserAvatar uid={(user || {}).uid} showName={false} />}
		>
			<MenuItem onClick={logoutHandler} className={classes.dropdownItem}>
				Logout
			</MenuItem>
		</ExpandableWidget>
	);
}
