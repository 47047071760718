import { ShoppingBasket, ShoppingBasketOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import Lang from '../../variables/i18n';
import { Navigate } from 'react-router';
import OrderDetails from '../../components/OrderDetails';
import { CreateButton } from '../../components/CommonButtons';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import Card from '../../components/Card/Card';
import { useStore } from '../../components/useSupercore';
import { SCModules } from '../../components/supercore/api';
import OrdersTable from '../../components/OrdersTable';
import CardTitle from '../../components/CardTitle';
import { actionShorthand } from '../../actions';
import FiltersToolbar from '../../components/FiltersToolbar';

function OrdersView() {
	const { state: store, dispatch } = useStore(SCModules.orders);
	const [pending, setPending] = useState(false);
	const { doLoadPage, doDelete } = actionShorthand({
		dispatch,
		module: SCModules.orders,
	});

	useEffect(() => {
		let mounted = true;

		setPending(true);
		doLoadPage().finally(() => mounted && setPending(false));

		return () => {
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Card>
			<CardHeader>
				<CardTitle title={OrdersRoute.name} icon={<ShoppingBasketOutlined />}>
					<CreateButton />
				</CardTitle>
				<FiltersToolbar
					useSearchBar
					filters={[
						{
							value: 'orders_paid',
							label: 'εξωφλημενες',
						},
						{
							value: 'orders_not_paid',
							label: 'μη εξωφλημενες',
						},
						{
							value: 'orders_eleven_only',
							label: 'Έντεκα',
						},
						{
							value: 'orders_eleven_exclude',
							label: 'Χωρις Έντεκα',
						},
						{
							value: 'orders_deficient_only',
							label: 'Μόνο ελλειψεις',
						},
					]}
				/>
				<FiltersToolbar
					subtitle="Γρηγορότερα φίλτρα"
					filters={[
						{
							value: 'orders_awaiting_pickup',
							label: 'Περιμενουν παραλαβή',
						},
						{
							value: 'orders_pending_work',
							label: 'Περιμενουν διεκπαιραιωση',
						},
						{
							value: 'orders_pending_photobook',
							label: 'Εκκρεμει παραλαβη Photobook',
						},
					]}
				/>
			</CardHeader>
			<CardBody>
				<OrdersTable
					useFilters
					store={store}
					pending={pending}
					handleDelete={async (id) => {
						try {
							setPending(true);
							await doDelete(id);
						} finally {
							setPending(false);
						}
					}}
				/>
			</CardBody>
		</Card>
	);
}

const OrdersRoute = {
	path: 'orders',
	name: Lang.orders,
	icon: ShoppingBasket,
	children: [
		{ path: '/', element: <OrdersView /> },
		{ path: 'create', element: <OrderDetails create /> },
		{ path: 'view', element: <Navigate to="../" /> },
		{ path: 'view/:orderId', element: <OrderDetails /> },
	],
};

export default OrdersRoute;
