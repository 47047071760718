import React, { useContext, useEffect, useState } from 'react';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';

import routes from './../routes.js';
import styles from './../assets/jss/material-dashboard-react/layouts/adminStyle.js';

import { Card } from '@material-ui/core';
import CardHeader from '../components/Card/CardHeader';
import CardBody from '../components/Card/CardBody';
import CustomInput from '../components/CustomInput/CustomInput';
import AnonNavbar from '../components/Navbars/AnonNavbar';
import CardFooter from '../components/Card/CardFooter';
import Lock from '@material-ui/icons/Lock';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { AppContext } from '../store/store';
import LayoutNotification from './utils';
import Lang from '../variables/i18n';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import { useLocation, useNavigate } from 'react-router';
import CustomFormControl from '../components/CustomFormControl';
import Ntf from '../components/Ntf';
import { actionShorthand } from '../actions';
import { SCModules } from '../components/supercore/api';
import { useStore } from '../components/useSupercore';
import UserAvatar from '../components/UserAvatar';

const useStyles = makeStyles(styles);

export default function ResetPassword({ ...rest }) {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const [ntfTxt, setNtfTxt] = useState();
	const { firebase, user } = useContext(AppContext);
	const [showPending, setShowPending] = useState(false);
	const [passwordsMatch, setPasswordsMatch] = useState(true);

	const [form, setForm] = useState({ passwordConfirm: '', password: '' });
	const { passwordConfirm, password } = form;
	const { dispatch, state } = useStore();
	const { isResetPassword, uid } = state[(user || {}).uid] || {};
	const { doUpdateUserPassword, doClearStore } = actionShorthand({
		dispatch,
		module: SCModules.passwordReset,
	});
	const { doLoadUser } = actionShorthand({
		dispatch,
		module: SCModules.users,
		id: uid,
	});

	console.log(location);
	if (!user) {
		navigate('/login', {
			replace: true,
			state: { from: { pathname: location.pathname } },
		});
	}

	useEffect(() => {
		uid && doLoadUser();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uid, isResetPassword]);

	const handleResetPassword = async (event) => {
		event.preventDefault();
		setNtfTxt(undefined);
		setShowPending(true);
		setPasswordsMatch(true);

		try {
			console.log(user.uid);
			await doUpdateUserPassword({ uid: user.uid, password: form.password });
			await doClearStore();
			await firebase.signOutUser();
		} catch (error) {
			const { code, message } = error;
			console.log(code);

			code === 'auth/weak-password' && setPasswordsMatch(false);
			code === 'auth/too-many-requests' && setPasswordsMatch(false);
			setNtfTxt(message);
		} finally {
			setShowPending(false);
		}
	};

	return (
		<div className={classes.wrapper}>
			<div className={classes.mainPanel + ' unauthContainer'}>
				<AnonNavbar routes={routes} {...rest} />
				<div className={classes.content}>
					<LayoutNotification />

					<Card className={classes.registerCard}>
						<CardHeader>
							<h2>{Lang.resetPassword.header}</h2>
						</CardHeader>
						<CardBody>
							<Ntf
								messages={ntfTxt}
								close
								onClose={() => setNtfTxt(undefined)}
							/>
							{user ? <UserAvatar uid={user.uid} showName /> : undefined}
							{isResetPassword && (
								<p>Ο κωδικός σας πρέπει να αλλάξει πριν συνεχίσετε</p>
							)}
							<form>
								<CustomFormControl>
									<CustomInput
										error={!passwordsMatch}
										inputProps={{
											id: 'password',
											'data-testid': 'password',
											type: 'password',
											disabled: showPending,
											placeholder: Lang.resetPassword.password,
											value: password,
											autoComplete: 'current-password',
											'aria-label': Lang.resetPassword.password,
											onInput: (e) =>
												setForm({ ...form, password: e.target.value }),
											startAdornment: (
												<InputAdornment position="start">
													<Lock />
												</InputAdornment>
											),
										}}
									/>

									<CustomInput
										error={!passwordsMatch}
										inputProps={{
											id: 'password',
											'data-testid': 'passwordConfirm',
											type: 'password',
											disabled: showPending,
											placeholder: Lang.resetPassword.passwordConfirm,
											value: passwordConfirm,
											autoComplete: 'current-password',
											'aria-label': Lang.resetPassword.passwordConfirm,
											onInput: (e) =>
												setForm({ ...form, passwordConfirm: e.target.value }),
											startAdornment: (
												<InputAdornment position="start">
													<Lock />
												</InputAdornment>
											),
										}}
									/>
								</CustomFormControl>
							</form>
						</CardBody>
						<CardFooter>
							<span />
							<Tooltip title={Lang.resetPassword.changePassword}>
								<span>
									<Fab
										aria-label="login"
										onClick={(e) => handleResetPassword(e)}
										disabled={showPending}
									>
										{showPending ? (
											<CircularProgress size={48} />
										) : (
											<ArrowForward />
										)}
									</Fab>
								</span>
							</Tooltip>
						</CardFooter>
					</Card>
				</div>
			</div>
		</div>
	);
}
