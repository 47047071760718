import Lang from '../../variables/i18n';
import GenericForm from '../GenericForm';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import OrderBrief from '../OrderBrief';
import { useStore } from '../useSupercore';
import { SCModules } from '../supercore/api';
import { actionShorthand } from '../../actions';

const TransactionForm = ({ form, setForm, orderId }) => {
	const { type } = form;
	const hasOrder = Boolean(orderId);

	const { state, dispatch } = useStore(SCModules.orders);
	// const [pending, setPending] = useState(false);
	const { doLoadEntry } = actionShorthand({
		dispatch,
		module: SCModules.orders,
		id: orderId,
	});

	useEffect(() => {
		let mounted = true;
		if (!hasOrder) {
			return;
		}

		try {
			hasOrder && mounted && doLoadEntry(orderId);
		} finally {
		}

		return () => {
			mounted = false;
		};
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderId]);

	const { amountPaid = 0, price } = state[orderId] || {};

	const pendingAmount = price - amountPaid;
	const hasPendingAmount = pendingAmount > 0;
	const helperText = hasOrder
		? `${Lang.amount} - Υπόλοιπο παραγγελίας: ${Lang.currencySymbol.euro}${
				price - amountPaid
		  }`
		: undefined;

	/*
	console.group(`txform - ${hasPendingAmount}`)
	console.log(pendingAmount)
	console.log(helperText)
	console.log(state[orderId] )
	console.groupEnd()
*/

	return (
		<>
			<OrderBrief show={hasOrder} orderId={orderId} />
			<GenericForm
				setFormState={setForm}
				formState={form}
				fields={[
					{
						id: 'amount',
						type: 'number',
						label: hasOrder ? helperText : Lang.amount,
						min: 0,
						max: hasPendingAmount ? pendingAmount : undefined,
						required: true,
					},
					{
						id: 'type',
						label: 'Έσοδα / Έξοδα',
						type: 'radio',
						options: [
							{
								label: 'Έσοδα',
								value: 'in',
								selected: type === 'in',
								disabled: !!orderId,
							},
							{
								label: 'Έξοδα',
								value: 'out',
								selected: type === 'out',
								disabled: !!orderId,
							},
						],
					},
					{
						id: 'source',
						type: 'select',
						label: 'Πηγή',
						required: true,
						options: [
							{
								label: 'Μετρητά',
								value: 'cash',
							},
							{
								label: 'Κάρτα',
								value: 'card',
							},
							{
								label: 'Τραπεζική κατάθεση',
								value: 'bank',
							},
						],
					},
					{
						id: 'shift',
						type: 'select',
						label: 'Βάρδια',
						required: true,
						options: [
							{
								label: 'Βάρδια 1',
								value: '1',
							},
							{
								label: 'Βάρδια 2',
								value: '2',
							},
						],
					},
					{
						id: 'notes',
						label: 'Σημειώσεις',
						type: 'textarea',
					},
				]}
			/>
		</>
	);
};

TransactionForm.propTypes = {
	form: PropTypes.object,
	setForm: PropTypes.func,
};

export default TransactionForm;
