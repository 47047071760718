import React, { useEffect, useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	backdrop: {
		zIndex: 100,
	},
	progress: {
		color: '#fff',
	},
}));

export const useBackdrop = (status) => {
	const [pending, setPending] = useState(false);
	useEffect(
		() => setPending(status === 'idle' || status === 'pending'),
		[status]
	);

	return [pending, setPending];
};

export default function GenericBackdrop({ open, ...props }) {
	const classes = useStyles();
	return (
		<Backdrop className={classes.backdrop} open={open} {...props}>
			<CircularProgress size={64} className={classes.progress} />
		</Backdrop>
	);
}
