import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import { SCModules } from '../supercore/api';
import { hashCode } from '../../utils';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { generateOnSettled, useQueryDocument } from '../ReactQueryUtils';

const colors = [
	'#e53935',
	'#d81b60',
	'#8e24aa',
	'#5e35b1',
	'#3949ab',
	'#1e88e5',
	'#039be5',
	'#00acc1',
	'#00897b',
	'#43a047',
	'#7cb342',
	'#c0ca33',
	'#fdd835',
	'#ffb300',
	'#fb8c00',
	'#f4511e',
];

const getInitials = ({ name, surname, displayName, email }) => {
	if (!name && !surname && !displayName && !email) {
		return 'N/A';
	}

	displayName = displayName ? displayName.toUpperCase() : email;

	if (!name && !surname && displayName) {
		return displayName.length === 2 ? displayName : displayName[0];
	}

	name = !name || !name.length ? displayName.split(' ', 2)[0] : name;
	surname =
		!surname || !surname.length ? displayName.split(' ', 2).pop() : surname;

	return (name[0] + surname[0]).toUpperCase();
};

const AvatarIcon = ({ uid, onClick, label, ...entry }) => (
	<Tooltip title={label}>
		<Avatar
			onClick={onClick}
			style={{
				backgroundColor: colors[hashCode(uid) % colors.length],
			}}
		>
			{getInitials(entry)}
		</Avatar>
	</Tooltip>
);

export default function UserAvatar({ uid, onClick, showName = false }) {
	const [pendingState, setPending] = useState(true);

	const [entry, isLoading] = useQueryDocument(
		SCModules.users,
		uid,
		generateOnSettled(undefined, setPending),
		{ enabled: !!uid }
	);
	const pending = pendingState || isLoading;

	const { displayName, email } = entry || {};

	const handleOnClick = (e) => typeof onClick === 'function' && onClick(e, uid);
	const label = displayName || email || 'Unknown User';

	return pending ? (
		<CircularProgress />
	) : showName ? (
		<Chip
			icon={<AvatarIcon label={label} {...entry} />}
			label={label}
			onClick={handleOnClick}
		/>
	) : (
		<AvatarIcon {...entry} onClick={handleOnClick} label={label} />
	);
}

UserAvatar.propTypes = {
	uid: PropTypes.string,
	showName: PropTypes.bool,
	onClick: PropTypes.func,
};
