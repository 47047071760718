import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Hidden from '@material-ui/core/Hidden';
import Poppers from '@material-ui/core/Popper';
// @material-ui/icons
import Album from '@material-ui/icons/Album';
// core components
import Button from './../../components/CustomButtons/Button.js';
import styles from './../../assets/jss/material-dashboard-react/components/headerLinksStyle.js';
import MenuList from '@material-ui/core/MenuList';

const useStyles = makeStyles(styles);

export default function ExpandableWidget({
	id,
	label,
	icon = <Album />,
	...props
}) {
	const classes = useStyles();

	const [isOpen, setIsOpen] = React.useState(null);

	const handleClickHeader = (event) =>
		setIsOpen(
			isOpen && isOpen.contains(event.target) ? null : event.currentTarget
		);

	const handleClose = () => {
		setIsOpen(null);
	};

	return (
		<div className={classes.manager}>
			<Button
				color={window.innerWidth > 959 ? 'transparent' : 'white'}
				justIcon={window.innerWidth > 959}
				simple={!(window.innerWidth > 959)}
				aria-owns={isOpen ? { id } : null}
				aria-haspopup="true"
				onClick={handleClickHeader}
				className={classes.buttonLink}
			>
				{icon}

				<Hidden mdUp implementation="css">
					<p className={classes.linkText}>{label}</p>
				</Hidden>
			</Button>
			<Poppers
				open={!!isOpen}
				anchorEl={isOpen}
				transition
				disablePortal
				className={
					classNames({ [classes.popperClose]: !isOpen }) +
					' ' +
					classes.popperNav
				}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						id="profile-menu-list-grow"
						style={{
							transformOrigin:
								placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList role="menu">{props.children}</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Poppers>
		</div>
	);
}
