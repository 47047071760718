import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Lang from '../../variables/i18n';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { concatSchool, formatName } from '../../utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import SCLink from '../SCLink';
import { SCModules } from '../supercore/api';
import { useStore } from '../useSupercore';
import { actionShorthand } from '../../actions';

export default function OrderBrief({ orderId, show = true }) {
	const { state, dispatch } = useStore(SCModules.orders);
	const [pending, setPending] = useState(false);
	const { doLoadEntry } = actionShorthand({
		dispatch,
		module: SCModules.orders,
		id: orderId,
	});

	useEffect(() => {
		let mounted = true;
		if (!show) {
			return;
		}

		try {
			show && setPending(true);
			show && doLoadEntry(orderId).finally(() => mounted && setPending(false));
		} finally {
			mounted && show && setPending(false);
		}

		return () => {
			mounted = false;
		};
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderId, show]);

	const {
		firstName,
		lastName,
		schoolId,
		schoolName,
		deptName,
		instName,
		eventDate,
		eventId,
		amountPaid,
		price,
	} = state[orderId] || {};

	return (
		show &&
		(!pending ? (
			<TableContainer component={Paper}>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{Lang.school}</TableCell>
							<TableCell align={'right'}>
								<SCLink
									module={SCModules.schools}
									entryId={schoolId}
									label={concatSchool({ instName, deptName, schoolName })}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{Lang.eventDate}</TableCell>
							<TableCell align={'right'}>
								<SCLink
									module={SCModules.events}
									entryId={eventId}
									label={eventDate}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{Lang.customer}</TableCell>
							<TableCell align={'right'}>
								{formatName({ firstName, lastName })}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{Lang.amountOwed}</TableCell>
							<TableCell align={'right'}>
								{`${Lang.currencySymbol.euro}${price - amountPaid}`}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		) : (
			<CircularProgress />
		))
	);
}

OrderBrief.propTypes = {
	show: PropTypes.bool,
	orderId: PropTypes.string,
};
