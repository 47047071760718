import Lang from '../../variables/i18n';
import React from 'react';

export const GrayText = (props) => (
	<span style={{ color: 'gray' }} {...props} />
);

const Currency = ({ symbol = Lang.currencySymbol.euro, value = 0, ...props }) =>
	Number.isNaN(Number(value)) ? (
		'N/A'
	) : (
		<span {...props}>
			<GrayText>{symbol}</GrayText>
			{Number(value)}
		</span>
	);

export default Currency;
