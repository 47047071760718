import React from 'react';
import TimeAgo from 'timeago-react';
import getTimeFormatted from './util.js';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { parseDateAndTime, parseTimestamp } from '../../utils';

const TimeField = ({ datetime = {}, reverse = false, ...props }) => {
	const dateStr = parseDateAndTime(datetime);
	const title = reverse ? getTimeFormatted(datetime) : dateStr;

	return (
		<Tooltip title={title}>
			{reverse ? (
				<span>{dateStr}</span>
			) : (
				<TimeAgo datetime={parseTimestamp(datetime)} {...props} />
			)}
		</Tooltip>
	);
};

export default TimeField;

TimeField.propTypes = {
	reverse: PropTypes.bool,
	datetime: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.object, //must include property _seconds
	]),
};
