import useSupercore from '../useSupercore';
import { SCModules } from '../supercore/api';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Lang from '../../variables/i18n';
import SCLink from '../SCLink';
import { concatSchool } from '../../utils';

export default function EventBrief({ id }) {
	const { store = {} } = useSupercore({
		refreshData: false,
		supercoreApiName: SCModules.events,
	});
	const { eventId, schoolId, instName, schoolName, deptName, eventDate } =
		store[id];

	console.group('eventbrief');
	console.log(store[id]);
	console.groupEnd();

	return (
		<Grid container>
			<Grid item xm={12} sm={6}>
				<InputLabel>
					{Lang.school} / {Lang.eventDate}
				</InputLabel>
			</Grid>
			<Grid item xm={12} sm={6}>
				<SCLink
					module={SCModules.schools}
					entryId={schoolId}
					label={concatSchool({ instName, deptName, schoolName })}
				/>
				<br />
				<SCLink module={SCModules.events} entryId={eventId} label={eventDate} />
			</Grid>
		</Grid>
	);
}
