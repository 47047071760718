import React, { useState } from 'react';
import { SCModules } from '../supercore/api';
import PropTypes from 'prop-types';
import { concatSchool } from '../../utils';
import ComboBox from '../ComboBox';
import { useQueryMultiple } from '../ReactQueryUtils';

export default function SchoolSelect({
	id,
	onChange,
	required = false,
	labelText = 'Επιλογή τμήματος',
	disabled = false,
	ignoreNoEvents = true,
	value,
}) {
	const [loadingState, setLoading] = useState(true);

	const [data, isLoading] = useQueryMultiple(
		SCModules.schools,
		setLoading,
		undefined,
		{ refetchOnReconnect: false, refetchOnWindowFocus: false }
	);
	const loading = loadingState || isLoading;

	const schools = data
		.filter(({ eventsCount = 0 }) => ignoreNoEvents || eventsCount)
		.sort((a, b) => concatSchool(a).localeCompare(concatSchool(b)));

	const val = data.find((school) => school.id === value) || '';

	return (
		<ComboBox
			id={id}
			options={schools}
			onChange={(e, val) => {
				e.preventDefault();
				onChange({ target: { value: val ? val.id : undefined } });
			}}
			getOptionLabel={(option) => concatSchool(option)}
			getOptionSelected={({ id }, val) => !schools.length || id === val.id}
			value={val}
			label={labelText}
			fullWidth
			autoSelect
			blurOnSelect
			loading={loading}
			required={required}
			disabled={disabled || loading}
			groupBy={({ instName }) => instName}
		/>
	);
}

SchoolSelect.propTypes = {
	id: PropTypes.string.isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onlyInstitution: PropTypes.bool,
	onlySchool: PropTypes.bool,
	disabled: PropTypes.bool,
	ignoreNoEvents: PropTypes.bool,
};
