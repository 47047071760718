// import 'date-fns';
import React, { useState } from 'react';
import { AppContext, useInitialiseStore } from './store/store';
import useCurrentUser from './components/Firebase/Firebase';
import { initApi } from './components/supercore/api';
import useUsers from './store/users';
import { useRoutes } from 'react-router';
import dashboardRoutes from './routes';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { StoreContext } from './components/useSupercore';
import { BrowserRouter } from 'react-router-dom';
import { FiltersContext, useInitialiseFiltersStore } from './store/filters';
import { QueryClient, QueryClientProvider } from 'react-query';

const App = () => {
	const [error, setError] = useState(null);
	const { state, dispatch } = useInitialiseStore();
	const { state: filterState, dispatch: filterDispatch } =
		useInitialiseFiltersStore();
	const [user, setUser, firebase] = useCurrentUser();
	const [usersState, usersReducer] = useUsers();
	initApi(firebase);

	const queryClient = new QueryClient({
		defaultOptions: {},
	});

	return (
		<QueryClientProvider client={queryClient}>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<AppContext.Provider
					value={{
						error,
						setError,
						firebase,
						user,
						setUser,
						usersState,
						usersReducer,
					}}
				>
					<StoreContext.Provider
						value={{
							state,
							dispatch,
						}}
					>
						<FiltersContext.Provider
							value={{
								state: filterState,
								dispatch: filterDispatch,
							}}
						>
							<BrowserRouter>
								<InnerRouter />
							</BrowserRouter>
						</FiltersContext.Provider>
					</StoreContext.Provider>
				</AppContext.Provider>
			</MuiPickersUtilsProvider>
		</QueryClientProvider>
	);
};

const InnerRouter = () => {
	return useRoutes(dashboardRoutes);
};

export default App;
