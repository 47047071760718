import { ToggleButton } from '@material-ui/lab';
import React from 'react';
import { FilterActions, useFiltersStore } from '../../store/filters';
import CustomInput from '../CustomInput/CustomInput';
import Lang from '../../variables/i18n';

export const applyFilters = (
	store,
	useFilters,
	filterState,
	filterDefinitions
) => {
	let results = store;
	if (!useFilters) {
		return store;
	}

	const keys = Object.keys(filterState);
	for (let i = 0; i < keys.length; i++) {
		if (!filterState[keys[i]]) {
			continue;
		}

		const fn = filterDefinitions[keys[i]];

		if (typeof fn !== 'function') {
			console.warn(`Missing filter: ${keys[i]}`);
			continue;
		}

		results = fn(results, filterState[keys[i]]);
	}

	return results;
};

const FilterButton = ({ label, value, selected = false, ...props }) => (
	<ToggleButton selected={selected} value={value} {...props}>
		{label}
	</ToggleButton>
);

export default function FiltersToolbar({
	filters = [],
	useSearchBar = false,
	subtitle = 'Γρήγορα φίλτρα',
}) {
	const { state, dispatch } = useFiltersStore();

	if (!filters.length && filters.length === 0) {
		throw new Error('missing filter definitions');
	}

	const { orders_search } = state;

	return (
		<div>
			{useSearchBar && (
				<div>
					<CustomInput
						id={'searchFilter'}
						labelText={Lang.search}
						formControlProps={{ size: 'medium' }}
						value={orders_search || ''}
						onChange={(e) =>
							dispatch({
								type: FilterActions.SET_FILTER_TO,
								value: e.target.value.trim(),
								filterName: 'orders_search',
							})
						}
					/>
				</div>
			)}
			<div>
				<label>{subtitle}</label>
			</div>
			<div>
				{filters.map(({ value, label }) => (
					<FilterButton
						key={`${value}${state[value]}`}
						label={label}
						value={value}
						selected={state[value]}
						onClick={() => {
							dispatch({
								type: FilterActions.SET_FILTER_TO,
								value: !state[value],
								filterName: value,
							});
						}}
					/>
				))}
			</div>
		</div>
	);
}
