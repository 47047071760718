import CustomFormControl from '../CustomFormControl';
import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

export default function CustomRadioGroup({
	id,
	label,
	options,
	required,
	error,
	value,
	onChange,
}) {
	return (
		<CustomFormControl
			required={required}
			error={error}
			htmlFor={id}
			label={label || 'Επιλογή'}
		>
			<RadioGroup
				aria-label={label}
				name={id}
				value={value}
				onChange={onChange}
			>
				{options.map(({ label, value: itemValue, disabled, selected }) => (
					<FormControlLabel
						key={`${id}_${label}`}
						control={<Radio checked={selected || itemValue === value} />}
						label={label}
						value={itemValue}
						disabled={disabled}
					/>
				))}
			</RadioGroup>
		</CustomFormControl>
	);
}
