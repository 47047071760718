import React, { useEffect, useState } from 'react';
import { actionShorthand } from '../../actions';
import { SCModules } from '../supercore/api';
import CustomFormControl from '../CustomFormControl';
import SCLink from '../SCLink';

const ComplementaryLink = ({ complementaryOf, dispatch, create = false }) => {
	const [complementaryId, setComplementaryId] = useState();
	const intValue = parseInt(complementaryId);
	const valid = !isNaN(intValue);
	const { doFindByField } = actionShorthand({
		dispatch,
		module: SCModules.orders,
	});

	useEffect(() => {
		let mounted = true;

		complementaryOf &&
			doFindByField('complementaryOf', intValue).then((res) => {
				res && mounted && setComplementaryId(res.id);
			});

		return () => {
			mounted = false;
		};
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [complementaryOf]);

	return create || !complementaryOf ? (
		<span />
	) : (
		<CustomFormControl label={''} required={false} error={!valid}>
			<SCLink
				label={'Συμπληρωματική'}
				module={SCModules.orders}
				entryId={complementaryId}
			/>
		</CustomFormControl>
	);
};

export default ComplementaryLink;
