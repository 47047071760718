import Dialog from '@material-ui/core/Dialog';
import React, { useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Lang from '../../variables/i18n';
import TransactionForm from '../TransactionForm';
import useSupercore from '../useSupercore';
import { SCModules } from '../supercore/api';
import { TxInitialData } from '../TransactionDetails';
import PropTypes from 'prop-types';
import { getRestActions } from '../ReactQueryUtils';
import Ntf from '../Ntf';

export default function PaymentModal({
	open = false,
	orderId,
	onClose,
	create = false,
	id,
}) {
	const [ntf, setNtf] = useState(undefined);
	const { store } = useSupercore({
		create,
		supercoreApiName: SCModules.vault,
	});

	const orderData = store[id] || {};

	const [form, setForm] = useState({});
	const [disableBtn, setDisableBtn] = useState(false);

	useEffect(() => {
		let mounted = true;

		mounted &&
			setForm({
				...form,
				...TxInitialData,
				...orderData,
				orderId,
				...(store || {})[id],
			});
		return () => {
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderId]);

	const { doCreate } = getRestActions(SCModules.vault);

	const { amount } = form;

	return (
		<Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">{Lang.newPayment}</DialogTitle>
			<DialogContent>
				<Ntf messages={ntf} close onClose={() => setNtf(undefined)} />
				<TransactionForm form={form} setForm={setForm} orderId={orderId} />
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>{Lang.cancel}</Button>
				<Button
					disabled={disableBtn}
					onClick={async () => {
						if (amount === 0) {
							setNtf('Το ποσό πρέπει να είναι >0');
							return;
						}

						const [status, result] = await doCreate(form, false, true);
						const { message } = result;

						if (status !== 201) {
							setNtf(message);
							return;
						}

						setNtf({ message: 'Αποθηκεύτηκε', status: 'success' });
						setDisableBtn(true);
						setTimeout(onClose, 1000);
					}}
					color="primary"
				>
					{Lang.save}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

PaymentModal.propTypes = {
	orderId: PropTypes.string,
	onClose: PropTypes.func,
	open: PropTypes.bool,
	create: PropTypes.bool,
};
