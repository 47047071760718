import GenericForm from '../GenericForm';
import React from 'react';

export const genericAddressFields = [
	{
		id: 'address1',
		label: 'Διεύθυνση',
		placeholder: 'πχ. "Όλύμπου 138"',
	},
	{
		id: 'address2',
		label: 'Περιοχή',
		placeholder: '',
	},
	{
		id: 'postcode',
		label: 'T.K',
		placeholder: 'πχ. 54635',
	},
	{
		id: 'city',
		label: 'Πόλη',
		placeholder: 'Θεσσαλονίκη',
	},
	{
		id: 'country',
		label: 'Χώρα',
		placeholder: '',
	},
];

export const GenericAddressForm = ({ setFormState, formState }) => (
	<GenericForm
		setFormState={setFormState}
		formState={formState}
		fields={genericAddressFields}
	/>
);
