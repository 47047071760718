import { SCModuleAPI } from '../components/supercore/util';
import { SCModules } from '../components/supercore/api';
import { Actions } from '../store/store';

export const actionShorthand = ({ dispatch, module, id }) => ({
	doLoadEntry: (loadId = id) => loadEntry(dispatch, module, loadId),
	doFindByField: (fieldName, fieldValue) =>
		searchEntryByField(dispatch, module, fieldName, fieldValue),
	doLoadPage: () => loadPage(dispatch, module),
	doLoadUser: (uid = id) => loadUser(dispatch, uid),
	doLoadUserPage: () => loadPage(dispatch, SCModules.users),
	doSave: (data) => handleSave(dispatch, module, data),
	doCreate: async (data) => await handleCreate(dispatch, module, data),
	doDelete: (delId = id) => handleDelete(dispatch, module, delId),
	doUpdateUserPassword: (data) => updateUserPassword(dispatch, data),
	doClearStore: () => clearStore(dispatch),
});

export const loadUser = async (dispatch, uid) => {
	if (!dispatch) {
		throw new Error('Missing param: dispatch');
	}
	if (!uid) {
		throw new Error('Missing param: uid');
	}

	const { get } = SCModuleAPI({
		module: SCModules.users,
		params: { path: uid },
	});

	try {
		const response = await get();
		const text = await response.text();
		const json = text ? JSON.parse(text) : {};

		dispatch({
			collection: SCModules.users,
			type: Actions.UPDATE_USER,
			id: uid,
			data: json,
		});
	} catch (e) {
		console.error(e);
	}
};

export const searchEntryByField = async (
	dispatch,
	module,
	fieldName,
	fieldValue
) => {
	if (!dispatch) {
		throw new Error('Missing param: dispatch');
	}
	if (!module) {
		throw new Error('Missing param: module');
	}
	if (!fieldName) {
		throw new Error('Missing param: fieldName');
	}
	if (!fieldValue) {
		throw new Error('Missing param: fieldValue');
	}

	const { get } = SCModuleAPI({
		module: module,
		params: { path: `${fieldName}/${fieldValue}` },
	});

	try {
		const response = await get();
		const text = await response.text();
		const json = text ? JSON.parse(text) : {};

		if (!response.ok) {
			console.warn(response);
			return;
		}

		dispatch({
			collection: module,
			type: Actions.UPDATE_PAGE,
			data: json,
		});

		return json;
	} catch (e) {
		console.error(e);
	}
};

export const loadEntry = async (dispatch, module, id) => {
	if (!dispatch) {
		throw new Error('Missing param: dispatch');
	}

	if (!module) {
		throw new Error('Missing param: module');
	}
	if (!id) {
		throw new Error('Missing param: id');
	}

	const { get } = SCModuleAPI({
		module: module,
		params: { path: id },
	});

	try {
		const response = await get();
		const text = await response.text();
		const json = text ? JSON.parse(text) : {};

		if (!response.ok) {
			console.warn(response);
			return;
		}

		dispatch({
			collection: module,
			type: Actions.UPDATE_SINGLE,
			id: id,
			data: json,
		});

		return json;
	} catch (e) {
		console.error(e);
	}
};

export const loadPage = async (dispatch, module, startAt = 0, limit = 1500) => {
	const { get } = SCModuleAPI({
		module,
	});

	try {
		const response = await get();
		const text = await response.text();
		const json = text ? JSON.parse(text) : {};

		dispatch({
			collection: module,
			type:
				module === SCModules.users
					? Actions.UPDATE_USER_PAGE
					: Actions.UPDATE_PAGE,
			data: json.data,
		});

		return json.data;
	} catch (e) {
		console.error(e);
	}
};

export const handleCreate = async (dispatch, module, data) => {
	if (!dispatch) {
		throw new Error('Missing param: dispatch');
	}

	if (!module) {
		throw new Error('Missing param: module');
	}
	if (!data) {
		throw new Error('Missing param: data');
	}

	const { create } = SCModuleAPI({
		module,
	});

	try {
		const response = await create(data);
		const { data: resData, message } = await response.json();

		if (!response.ok) {
			return {
				fail: true,
				error: message,
			};
		}

		const { id: responseId } = resData || {};

		dispatch({
			type: Actions.UPDATE_SINGLE,
			collection: module,
			id: responseId,
			data: resData,
		});

		return resData;
	} catch (e) {
		console.warn(e);
		return {
			fail: true,
			error: e.message,
		};
	}
};

export const handleSave = async (dispatch, module, data) => {
	if (!dispatch) {
		throw new Error('Missing param: dispatch');
	}
	if (!module) {
		throw new Error('Missing param: module');
	}
	if (!data) {
		throw new Error('Missing param: data');
	}

	const { id } = data;

	if (!id) {
		throw new Error('Missing data: id');
	}
	const { update } = SCModuleAPI({
		module,
	});

	try {
		const response = await update(id, data);
		const {
			data: responseData,
			message,
			id: responseId,
		} = await response.json();

		if (!response.ok) {
			return {
				fail: true,
				error: message,
			};
		}

		dispatch({
			type: Actions.UPDATE_SINGLE,
			collection: module,
			id: responseId,
			data: responseData,
		});

		return responseData;
	} catch (e) {
		console.warn(e);
		return {
			fail: true,
			error: e.message,
		};
	}
};

export const handleDelete = async (dispatch, module, id) => {
	if (!dispatch) {
		throw new Error('Missing param: dispatch');
	}

	if (!module) {
		throw new Error('Missing param: module');
	}

	if (!id) {
		throw new Error('Missing param: id');
	}

	const { delete: doDelete } = SCModuleAPI({
		module,
	});

	try {
		const { data: responseData } = await (await doDelete(id)).json();
		dispatch({ type: Actions.DELETE, collection: module, id });

		return responseData;
	} catch (e) {
		console.warn(e);
	}
};

export const clearStore = async (dispatch) =>
	dispatch({ type: Actions.INIT_STORE });

export const updateUserPassword = async (dispatch, { uid, ...data }) =>
	handleSave(dispatch, SCModules.passwordReset, { ...data, id: uid });
