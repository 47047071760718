import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
// @material-ui/icons
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
// core components
import { customInputTableStyle } from './../../assets/jss/material-dashboard-react/components/customInputStyle.js';

const useStyles = makeStyles(customInputTableStyle);

export default function CustomInput({
	formControlProps = {},
	labelText,
	id,
	labelProps,
	inputProps,
	error,
	// helperText,
	success,
	//input field
	placeholder,
	defaultValue,
	onChange,
	onFocus,
	onBlur,
	readOnly = false,
	value,
}) {
	const classes = useStyles();

	const labelClasses = classNames({
		[' ' + classes.labelRootError]: error,
		[' ' + classes.labelRootSuccess]: success && !error,
	});
	const underlineClasses = classNames({
		[classes.underlineError]: error,
		[classes.underlineSuccess]: success && !error,
		[classes.underline]: true,
	});
	const marginTop = classNames({
		[classes.marginTop]: labelText === undefined,
	});
	return (
		<FormControl
			{...formControlProps}
			className={`${formControlProps.className || ''} ${
				classes.formControl || ''
			}`}
		>
			{labelText !== undefined ? (
				<InputLabel
					className={classes.labelRoot + labelClasses}
					htmlFor={id}
					{...labelProps}
				>
					{labelText}
				</InputLabel>
			) : null}
			<Input
				classes={{
					root: marginTop,
					disabled: classes.disabled,
					underline: underlineClasses,
				}}
				id={id}
				defaultValue={defaultValue}
				onChange={onChange}
				onBlur={onBlur}
				onFocus={onFocus}
				error={error}
				readOnly={readOnly}
				placeholder={placeholder}
				value={value}
				// helperText={helperText}
				{...inputProps}
			/>
			{error ? (
				<Clear className={classes.feedback + ' ' + classes.labelRootError} />
			) : success ? (
				<Check className={classes.feedback + ' ' + classes.labelRootSuccess} />
			) : null}
		</FormControl>
	);
}

CustomInput.propTypes = {
	labelText: PropTypes.node,
	labelProps: PropTypes.object,
	id: PropTypes.string,
	inputProps: PropTypes.object,
	formControlProps: PropTypes.object,
	error: PropTypes.bool,
	success: PropTypes.bool,
};
