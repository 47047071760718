import React, { useEffect } from 'react';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import Navbar from './../components/Navbars/Navbar.js';
import Footer from './../components/Footer/Footer.js';
import Sidebar from './../components/Sidebar/Sidebar.js';

import routes from './../routes.js';

import styles from './../assets/jss/material-dashboard-react/layouts/adminStyle.js';

import bgImage from './../assets/img/sidebar-2.jpg';
import logo from './../assets/img/reactlogo.png';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { useAuthorisedPage } from '../components/Firebase/Firebase';
import { SCModules } from '../components/supercore/api';
import { useQueryDocument } from '../components/ReactQueryUtils';

let ps;
const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
	// styles
	const classes = useStyles();
	// ref to help us initialize PerfectScrollbar on windows devices
	const mainPanel = React.createRef();
	// states and functions
	const image = bgImage;
	const color = 'blue';
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
	const navigate = useNavigate();
	const location = useLocation();

	const { user } = useAuthorisedPage();

	const resizeFunction = () => {
		if (window.innerWidth >= 960) {
			setMobileOpen(false);
		}
	};

	const { uid, isResetPassword = false } = useQueryDocument(
		SCModules.users,
		(user || {}).uid,
		undefined,
		{
			refetchInterval: 80_000,
		}
	);

	// initialize and destroy the PerfectScrollbar plugin
	useEffect(() => {
		if (!user) {
			navigate('/login', {
				replace: true,
				state: { from: { pathname: location.pathname } },
			});
		}

		if (isResetPassword) {
			console.log(`navigating to resetPassword`);
			navigate('/resetPassword', {
				replace: true,
				state: {
					from: { pathname: location.pathname },
					isResetPassword: true,
					uid,
				},
			});
		}

		if (navigator.platform.indexOf('Win') > -1) {
			ps = new PerfectScrollbar(mainPanel.current, {
				suppressScrollX: true,
				suppressScrollY: false,
			});
			document.body.style.overflow = 'hidden';
		}
		window.addEventListener('resize', resizeFunction);

		// Specify how to clean up after this effect:
		return () => {
			if (navigator.platform.indexOf('Win') > -1) {
				ps.destroy();
			}
			window.removeEventListener('resize', resizeFunction);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, isResetPassword, location, navigate]);
	return (
		<div className={classes.wrapper}>
			<Sidebar
				routes={routes}
				logoText={'Fotomada'}
				logo={logo}
				image={image}
				handleDrawerToggle={handleDrawerToggle}
				open={mobileOpen}
				color={color}
				{...rest}
			/>
			<div className={classes.mainPanel} ref={mainPanel}>
				<Navbar
					routes={routes}
					handleDrawerToggle={handleDrawerToggle}
					{...rest}
				/>
				<div className={classes.content}>
					<div className={classes.container}>
						<Outlet />
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
