import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
// core components
import AdminNavbarLinks from './../../components/Navbars/AdminNavbarLinks.js';

import styles from './../../assets/jss/material-dashboard-react/components/sidebarStyle.js';
import { useNavigate } from 'react-router';
import { SystemProperties } from '../../variables/sysSettings';
import * as AppVersionStatic from '../../appVersion.json';

const useStyles = makeStyles(styles);

const AppVersion = () => {
	const classes = useStyles();
	const { major, minor, patch, hash } = AppVersionStatic;
	return (
		<span className={classes.appVersion}>
			Version: {major}.{minor}.{patch}-{hash || 'DEV'}
		</span>
	);
};

export default function Sidebar(props) {
	const { color, logo, image, logoText, routes } = props;
	const classes = useStyles();
	const navigate = useNavigate();
	// verifies if routeName is the one active (in browser input)
	const activeRoute = (routeName) =>
		window.location.href.indexOf(routeName) > -1;
	const sidebarRoutes = routes
		.filter(({ path }) => SystemProperties.dashboardRootPath.val === path)
		.map(({ children }) => children)
		.flat(1);

	const Links = (
		<List className={classes.list}>
			{sidebarRoutes.map(({ path, name, ...props }, key) => {
				const fullPath = `${SystemProperties.dashboardRootPath.val}/${path}`;
				const listItemClasses = classNames({
					[classes[color]]: activeRoute(fullPath),
				});
				const whiteFontClasses = classNames({
					[classes.whiteFont]: activeRoute(fullPath),
				});
				return (
					<NavLink
						onClick={() => navigate(fullPath)}
						to={fullPath}
						className={classes.item}
						activeClassName="active"
						key={key}
					>
						<ListItem
							button
							className={classes.itemLink + ' ' + listItemClasses}
						>
							{typeof props.icon === 'string' ? (
								<Icon
									className={classNames(
										classes.itemIcon,
										' ' + whiteFontClasses,
										{
											[classes.itemIconRTL]: props.rtlActive,
										}
									)}
								>
									{props.icon}
								</Icon>
							) : (
								<props.icon
									className={classNames(classes.itemIcon, whiteFontClasses, {
										[classes.itemIconRTL]: props.rtlActive,
									})}
								/>
							)}
							<ListItemText
								primary={name}
								className={classNames(classes.itemText, whiteFontClasses)}
								disableTypography={true}
							/>
						</ListItem>
					</NavLink>
				);
			})}
		</List>
	);
	const brand = (
		<div className={classes.logo}>
			<a
				href="https://fire.fotomada.gr/"
				className={classNames(classes.logoLink, {
					[classes.logoLinkRTL]: props.rtlActive,
				})}
				target="_blank"
				rel="noopener noreferrer"
			>
				<div className={classes.logoImage}>
					<img src={logo} alt="logo" className={classes.img} />
				</div>
				{logoText}
			</a>
		</div>
	);
	return (
		<div>
			<Hidden mdUp implementation="css">
				<Drawer
					variant="temporary"
					anchor={props.rtlActive ? 'left' : 'right'}
					open={props.open}
					classes={{
						paper: classNames(classes.drawerPaper, {
							[classes.drawerPaperRTL]: props.rtlActive,
						}),
					}}
					onClose={props.handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
				>
					{brand}
					<div className={classes.sidebarWrapper}>
						<AdminNavbarLinks />
						{Links}
						<AppVersion />
					</div>
					{image && (
						<div
							className={classes.background}
							style={{ backgroundImage: 'url(' + image + ')' }}
						/>
					)}
				</Drawer>
			</Hidden>
			<Hidden smDown implementation="css">
				<Drawer
					anchor={props.rtlActive ? 'right' : 'left'}
					variant="permanent"
					open
					classes={{
						paper: classNames(classes.drawerPaper, {
							[classes.drawerPaperRTL]: props.rtlActive,
						}),
					}}
				>
					{brand}
					<div className={classes.sidebarWrapper}>
						{Links}
						<AppVersion />
					</div>
					{image && (
						<div
							className={classes.background}
							style={{ backgroundImage: 'url(' + image + ')' }}
						/>
					)}
				</Drawer>
			</Hidden>
		</div>
	);
}

Sidebar.propTypes = {
	rtlActive: PropTypes.bool,
	handleDrawerToggle: PropTypes.func,
	bgColor: PropTypes.oneOf(['purple', 'blue', 'green', 'orange', 'red']),
	logo: PropTypes.string,
	image: PropTypes.string,
	logoText: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
	open: PropTypes.bool,
};
