import { useNavigate } from 'react-router';
import { default as MUILink } from '@material-ui/core/Link';
import React from 'react';

export default function Link({ to = '#', replace = false, ...props }) {
	const navigate = useNavigate();

	return (
		<MUILink
			href={to}
			onClick={(e) => e.preventDefault() || navigate(to, { replace })}
			{...props}
		/>
	);
}
