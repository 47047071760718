import GridItem from '../Grid/GridItem';
import { Dimension } from '../../variables/general';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import Lang from '../../variables/i18n';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import { CancelButton, CreateButton, SaveButton } from '../CommonButtons';
import { Event } from '@material-ui/icons';
import GenericAdditionalInfo from '../GenericAdditionalInfo';
import GridContainer from '../Grid/GridContainer';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useStore } from '../useSupercore';
import { SCModules } from '../supercore/api';
import TransactionForm from '../TransactionForm';
import { actionShorthand } from '../../actions';
import Ntf from '../Ntf';

export const TxInitialData = {
	timestamp: new Date(),
	type: 'in',
	amount: 0,
};

export default function TransactionDetails({ create = false }) {
	const [ntfTxt, setNtfTxt] = useState();
	const navigate = useNavigate();
	const [pending, setPending] = useState(false);
	const { transactionId } = useParams();
	const { state, dispatch } = useStore();
	const { doLoadEntry, doSave, doCreate } = actionShorthand({
		dispatch,
		module: SCModules.vault,
		id: transactionId,
	});

	const txData = (state[SCModules.vault] || {})[transactionId];

	useEffect(() => {
		let mounted = true;

		if (create) {
			return;
		}

		mounted && setPending(true);
		mounted &&
			doLoadEntry(transactionId)
				.then((response) => mounted && setForm(response))
				.finally(() => {
					mounted && setPending(false);
				});

		return () => {
			mounted = false;
		};
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transactionId]);

	const [form, setForm] = useState({ ...TxInitialData, ...txData });
	const { orderId } = form;

	return (
		<GridContainer>
			<GridItem {...Dimension.md}>
				<Card>
					<CardHeader>
						<h4>{Lang.transactionDetails.title}</h4>
					</CardHeader>
					<CardBody>
						<Ntf messages={ntfTxt} close onClose={() => setNtfTxt(undefined)} />
						<TransactionForm
							transactionId={transactionId}
							create={create}
							orderId={orderId}
							form={form}
							setForm={setForm}
						/>
					</CardBody>
					<CardFooter>
						<CancelButton />
						{create ? (
							<CreateButton
								icon={<Event />}
								disabled={pending}
								onClick={async () => {
									try {
										setPending(true);
										const { id, fail, error } = await doCreate(form);
										fail
											? setNtfTxt(error)
											: navigate(`/admin/cash/view/${id}`);
									} finally {
										setPending(false);
									}
								}}
							/>
						) : (
							<SaveButton
								disabled={pending}
								onClick={async () => {
									try {
										setPending(true);
										const { fail, error } = await doSave(form);

										if (fail) {
											setNtfTxt(error);
										}
									} finally {
										setPending(false);
									}
								}}
							/>
						)}
					</CardFooter>
				</Card>
			</GridItem>
			{!create && (
				<GridItem {...Dimension.md}>
					<GenericAdditionalInfo
						show
						collection={'transactions'}
						id={transactionId}
					/>
				</GridItem>
			)}
		</GridContainer>
	);
}
