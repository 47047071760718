import React from 'react';
import { makeStyles } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Toolbar from '@material-ui/core/Toolbar';
import {
	blackColor,
	hexToRgb,
} from '../../assets/jss/material-dashboard-react';
import GenericActions from '../GenericActions';
import { ChipContainer, DeletedChip } from '../CommonChips';
import Typography from '@material-ui/core/Typography';
import getTimeFormatted from '../TimeField/util';

const useStyles = makeStyles(() => ({
	root: {
		flexGrow: 1,
	},
	title: {
		flexGrow: 1,
	},
	headerIcon: {
		width: '24px',
		height: '30px',
		fontSize: '24px',
		lineHeight: '30px',
		float: 'left',
		marginRight: '15px',
		textAlign: 'center',
		verticalAlign: 'middle',
		color: 'rgba(' + hexToRgb(blackColor) + ', 0.8)',
		marginTop: '-2px',
	},
}));

export default function CardTitle({
	id,
	lastEdit,
	handleDelete,
	onDeleteComplete,
	createTitle,
	editTitle,
	title,
	icon,
	actions,
	isDeleted = false,
	create = false,
	children,
}) {
	const classes = useStyles();
	editTitle = editTitle || createTitle;

	return (
		<>
			<Toolbar className={classes.root}>
				{icon && <Icon className={classes.headerIcon}>{icon}</Icon>}
				<h4 className={classes.title}>
					{title || (create ? createTitle : editTitle)}
				</h4>
				{children}
				{!create && actions && (
					<GenericActions
						id={id}
						isDeleted={isDeleted}
						handleDelete={handleDelete}
						onDeleteComplete={onDeleteComplete}
						actions={Array.isArray(actions) ? actions : []}
					/>
				)}
			</Toolbar>
			{lastEdit && (
				<Typography variant={'caption'}>
					Τελευταία ενημέρωση: {getTimeFormatted(lastEdit * 1000)}
				</Typography>
			)}
			<ChipContainer show={isDeleted}>
				<DeletedChip show={isDeleted} />
			</ChipContainer>
		</>
	);
}
