import React, { useState } from 'react';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CustomInput from '../CustomInput/CustomInput';
import CardFooter from '../Card/CardFooter';
import { CreateButton } from '../CommonButtons';
import PropTypes from 'prop-types';
import { InsertComment } from '@material-ui/icons';
import Lang from '../../variables/i18n';

export default function CommentEditor({
	commentId,
	topicId,
	collection,
	handleCreate,
}) {
	const [body, setBody] = useState('');

	const doSave = async () => {
		if (body.trim().length === 0) {
			return;
		}

		await handleCreate(
			{
				topicId,
				kind: 'comment',
				parentId: topicId,
				parentCollection: collection,
				body: body.trim(),
			},
			false,
			true
		);

		setBody('');
	};

	return (
		<Card>
			<CardBody>
				<CustomInput
					id={'commentEditor'}
					onChange={({ target }) => setBody(target.value)}
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						placeholder: 'Νέο σχόλιο',
						multiline: true,
						rows: 4,
					}}
				/>
			</CardBody>
			<CardFooter>
				<span />
				<CreateButton
					title={commentId ? Lang.save : Lang.create}
					icon={<InsertComment />}
					onClick={doSave}
				/>
			</CardFooter>
		</Card>
	);
}

CommentEditor.propTypes = {
	topicId: PropTypes.string.isRequired,
	collection: PropTypes.string.isRequired,
	handleCreate: PropTypes.func,
	handleSave: PropTypes.func,
};
