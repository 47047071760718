import React from 'react';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

export default function TaskCheckbox({ id, label, checked, onChange }) {
	return (
		<FormControlLabel
			control={
				<Checkbox
					id={id}
					color={'primary'}
					checked={checked}
					onChange={onChange}
				/>
			}
			label={
				<Box display={'flex'}>
					<AssignmentTurnedInIcon />
					{label}
				</Box>
			}
		/>
	);
}
