import { createContext, useContext, useReducer } from 'react';

const initialState = {};
export const FiltersContext = createContext(initialState);

export const FilterActions = {
	SET_FILTER_TO: 'SET_FILTER_TO',
	UNSET_ALL: 'UNSET_ALL',
};

function reducer(state, action) {
	const { type, filterName, value } = action;
	let newState = { ...state };

	if (!filterName) {
		throw new Error('Missing filter name');
	}

	switch (type) {
		case FilterActions.SET_FILTER_TO:
			newState[filterName] = value;
			break;
		case FilterActions.UNSET_ALL:
			newState = initialState;
			break;
		default:
			throw new Error(`Unrecognised type: ${type}`);
	}

	return newState;
}

export const useFiltersStore = (scope) => {
	const { state, dispatch } = useContext(FiltersContext);

	const scoped = !scope
		? state
		: Object.keys(state)
				.filter((key) => key.startsWith(scope))
				.reduce((acc, curr) => {
					acc[curr] = state[curr];
					return acc;
				}, {});

	return { state: scoped, dispatch };
};

export const useInitialiseFiltersStore = () => {
	const [state, dispatch] = useReducer(reducer, {});

	return { state, dispatch };
};
