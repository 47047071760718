import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Lang from '../../variables/i18n';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function DeleteModal({
	open = false,
	title = Lang.delete,
	message = <p>Permanently delete this entry?</p>,
	onClose,
	onClick,
	children,
}) {
	const [pending, setPending] = useState(false);
	const doOnClick = async () => {
		try {
			setPending(true);
			onClick();
			onClose();
		} catch (e) {
			console.error(e);
		} finally {
			setPending(false);
		}
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle id="delete-dialog">{title}</DialogTitle>
			<DialogContent>
				{message}
				{open && children ? children : undefined}
			</DialogContent>
			<DialogActions>
				<Button disabled={pending} onClick={onClose} autoFocus>
					{Lang.cancel}
				</Button>
				<Button disabled={pending} onClick={doOnClick} color="primary">
					{pending && <CircularProgress size={16} />}
					{Lang.delete}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

DeleteModal.propTypes = {
	onClose: PropTypes.func,
	onClick: PropTypes.func,
	open: PropTypes.bool,
	children: PropTypes.any,
	title: PropTypes.string,
	message: PropTypes.any,
};
