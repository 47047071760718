import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import GridContainer from '../Grid/GridContainer';
import { Dimension } from '../../variables/general';
import GridItem from '../Grid/GridItem';
import FormLabel from '@material-ui/core/FormLabel';
import * as yup from 'yup';
import FormItem from '../FormItem';

export default function GenericForm({
	fields,
	setFormState,
	formState,
	formLabel,
	setIsValid = () => {},
}) {
	const [errors, setErrors] = useState({});
	const schemaValidation = useMemo(
		() =>
			yup.object().shape(
				fields
					.filter(({ yup: yupObj }) => !!yupObj)
					.map(({ id, yup: yupObj }) => ({
						[id]: yupObj,
					}))
					.reduce((acc, curr) => ({ ...acc, ...curr }), {})
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const handleTimeUpdate = (id, timeStr) =>
		setFormState({ ...formState, [id]: timeStr });
	const handleNumberUpdate = (id, e) => {
		e.preventDefault();
		setFormState({ ...(formState || {}), [id]: Number(e.target.value) });
	};

	const handleSelectUpdate = (id, v) => {
		setFormState({ ...(formState || {}), [id]: v });
	};
	const handleFormUpdate = (id, e) => {
		e.preventDefault && e.preventDefault();
		setFormState({ ...(formState || {}), [id]: e.target.value });
	};
	const handleToggleCheckbox = (id) =>
		setFormState({ ...(formState || {}), [id]: !formState[id] });

	useEffect(() => {
		let mounted = true;

		mounted && setErrors({});
		schemaValidation
			.validate(formState, { abortEarly: false })
			.then(() => {
				mounted && setIsValid(true);
			})
			.catch(({ inner }) => {
				mounted &&
					setErrors(
						inner
							.map(({ path }) => path)
							.reduce((acc, curr) => (acc[curr] = true) && acc, {})
					);
				mounted && setIsValid(false);
			});
		return () => {
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [schemaValidation, formState]);

	return (
		<GridContainer spacing={4}>
			{formLabel && (
				<GridItem {...Dimension.formItemFullWidth}>
					<FormLabel>{formLabel}</FormLabel>
				</GridItem>
			)}
			{fields
				.filter((field) => field !== undefined)
				.filter(({ display = true }) => display)
				.map(
					({
						id,
						type,
						fullWidth = type === 'textarea',
						size = { ...Dimension.formItem },
						shrink,
						key,
						...props
					}) => (
						<GridItem
							key={key || id}
							{...(((fullWidth || type === 'formLabel') &&
								Dimension.formItemFullWidth) ||
								size)}
							gridItemClassName={
								type === 'formLabel' ? 'genericFormLabelRow' : ''
							}
						>
							<FormItem
								id={id}
								type={type}
								shrink={shrink}
								error={errors[id]}
								value={formState[id]}
								handleTimeUpdate={(e) => handleTimeUpdate(id, e)}
								handleNumberUpdate={(e) => handleNumberUpdate(id, e)}
								handleFormUpdate={(e) => handleFormUpdate(id, e)}
								handleToggleCheckbox={(e) => handleToggleCheckbox(id, e)}
								handleSelectUpdate={handleSelectUpdate}
								{...props}
							/>
						</GridItem>
					)
				)}
		</GridContainer>
	);
}

GenericForm.propTypes = {
	fields: PropTypes.arrayOf(PropTypes.object).isRequired,
	setFormState: PropTypes.func.isRequired,
	formState: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
		.isRequired,
	formLabel: PropTypes.string,
	setIsValid: PropTypes.func,
};
