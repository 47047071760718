/*
Guidelines:
It is impossible to have exactly accurate translation between any two given languages.
The best we can do is make it easier for ourselves by planning ahead.
---
Some keys are are on the root level, some are nested. If the key is a one-word noun,
it can probably be put on the root level. This means it can be re-used across modules.
Phrases, sentences, user-action descriptions, tooltips etc. should be nested
by module, view, component, or whatever makes sense.
 */

import SystemSettingsDefaults from './sysSettings';

const en = {
	_locale: {
		name: 'English', //local name
		name_int: 'English', //international name
		icon: '',
	},
	firebaseErrors: {
		genericError:
			"An error has occurred and we don't have an error message for it. Please speak to an administrator",
		'auth/wrong-password': 'Wrong password',
		'auth/user-not-found': 'User not found',
		'auth/too-many-requests':
			'Too many failed requests. Back off for a bit, or speak to an administrator',
	},
	add: 'Add',
	action: 'Action',
	addNewClient: 'Add new client',
	addNewOrder: 'Add new order',
	abort: 'Abort',
	active: 'Active',
	address: 'Address',
	amount: 'Amount',
	amountOwed: 'Amount owed',
	amountTotal: 'Total amount',
	apply: 'Apply',
	back: 'Back',
	category: 'Category',
	categories: 'Categories',
	copyToClipboard: 'Copy to clipboard',
	cancel: 'Cancel',
	create: 'Create',
	client: 'Client',
	clients: 'Clients',
	customer: 'Customer',
	customers: 'Customers',
	customerDetails: 'Customer details',
	details: 'Details',
	customersList: 'Customers list',
	delete: 'Delete',
	displayName: 'Display name',
	draft: 'Draft',
	date: 'Date',
	edit: 'Edit',
	email: 'Email',
	error: 'Error',
	events: 'Events',
	forward: 'Forward',
	help: 'Help',
	icon: 'Icon',
	info: 'Info',
	inactive: 'Inactive',
	image: 'Image',
	information: 'Information',
	left: 'Left',
	memberSince: 'Member since',
	name: 'Name',
	next: 'Next',
	ok: 'Ok',
	order: 'Order',
	orders: 'Orders',
	password: 'Password',
	picture: 'Picture',
	preview: 'Preview',
	previous: 'Previous',
	price: 'Price',
	priceWithVat: 'Price + VAT',
	product: 'Product',
	products: 'Products',
	phoneNumber: 'Phone number',
	publish: 'Publish',
	registeredUsers: 'Registered Users',
	rowsPerPage: 'Rows per page',
	right: 'Right',
	save: 'Save',
	saveDraft: 'Save Draft',
	surname: 'Surname',
	status: 'Status',
	time: 'Time',
	user: 'User',
	users: 'Users',
	vat: 'VAT',
	warning: 'Warning',
	newPayment: 'New payment',
	search: 'Search',
	currencySymbol: {
		euro: '€',
	},
	ordersView: {
		paymentDetails: {
			title: 'Payments',
			total: 'Total',
			creationDate: 'Creation date',
		},
	},
	resetPassword: {
		header: 'Reset Password',
		password: 'Password',
		passwordConfirm: 'Confirm new Password',
		changePassword: 'New password',
	},
	login: {
		header: 'Log In',
		email: 'E-mail',
		password: 'Password',
		login: 'Log in',
		createAccount: 'Create Account',
	},
	customersView: {
		ordersNumber: 'Number of orders',
	},
	register: {
		header: 'Create Account',
		join: 'Join',
		email: 'E-mail',
		password: 'Password',
		passwordAgain: 'Verify password',
		publicName: 'Your display name (public)',
		backToLogin: 'Back to Login',
		error: {
			registerEmailValid: 'Must be a valid e-mail address',
			registerPassValid: 'Must be 8 characters or more',
			registerPass2Valid: 'Passwords must match',
		},
	},

	/**
	 * System settings.
	 * @see SystemSettingsDefaults
	 */
	settings: {
		defaultLocale: {
			label: 'Default Locale',
			desc: 'Sets the default language for the admin dashboard.',
		},
		allowSelfSignup: {
			label: 'Enable new user registration',
			type: 'checkbox',
			desc: 'Enables the user registration page. Turning this off means only administrators can create new accounts.',
		},
	},
};
const gr = {
	...en,
	_locale: {
		name: 'Ελληνικά',
		name_int: 'Greek',
		icon: '',
	},
	firebaseErrors: {
		'auth/wrong-password': 'Λάθος κωδικός',
		'auth/user-not-found': 'Δεν βρέθηκε χρήστης με αυτό το email',
		'auth/too-many-requests':
			'Πολλαπλές αποτυχημένες προσπάθειες. Προσπαθήστε ξανά σε λίγο ή βρείτε κάποιον διαχειριστή',
	},
	add: 'Προσθήκη',
	addNewClient: 'Καταχώρηση νέου πελάτη',
	amount: 'Ποσό',
	amountOwed: 'Υπόλοιπο',
	amountTotal: 'Συνολικό ποσό',
	clients: 'Πελάτες',
	client: 'Πελάτης',
	copyToClipboard: 'Αντιγραφή',
	create: 'Δημιουργία',
	customer: 'Πελάτης',
	customers: 'Πελάτες',
	customerDetails: 'Στοιχεία πελάτη',
	customersList: 'Πελατολόγιο',
	category: 'Κατηγορία',
	categories: 'Κατηγορίες',
	date: 'Ημερομηνία',
	datetime: 'Ημ/νία και Ώρα',
	delete: 'Διαγραφή',
	description: 'Περιγραφή',
	edit: 'Επεξεργασία',
	events: 'Ορκομωσίες',
	name: 'Όνομα',
	product: 'Προιόν',
	products: 'Προιόντα',
	phoneNumber: 'Αρ. τηλεφώνου',
	surname: 'Επώνυμο',
	newPayment: 'Νέα πληρωμή',
	orders: 'Παραγγελίες',
	order: 'Παραγγελία',
	price: 'Τιμή',
	priceWithVat: 'Τιμή + ΦΠΑ',
	info: 'Πληροφορίες',
	cancel: 'Άκυρο',
	save: 'Αποθήκευση',
	back: 'Πίσω',
	forward: 'Μπροστά',
	next: 'Επόμενο',
	previous: 'Προηγούμενο',
	left: 'Αριστερά',
	rowsPerPage: 'Σειρές ανα σελίδα',
	right: 'Δεξια',
	time: 'Ώρα',
	vat: 'ΦΠΑ',
	school: 'Σχολή',
	eventDate: 'Ημ. ορκομωσίας',
	orderDate: 'Ημ. παραγγελίας',
	search: 'Αναζήτηση',
	login: {
		header: 'Σύνδεση',
		login: 'Συνδεση',
		createAccount: 'Εγγραφή',
	},
	vaultView: {
		title: 'Ταμείο',
		addedBy: 'Μέλος',
		totalAmountAfter: 'Ποσό μετά',
		newTransaction: 'Νέα συναλλαγή',
		order: 'Παραγγελία',
		source: 'Πηγή',
	},
	transactionDetails: {
		title: 'Συναλλαγή',
		transactions: 'Συναλλαγές',
		create: 'Καταχώρηση νέας συναλλαγής',
	},
	txSource: {
		cash: 'Μετρητά',
		card: 'Κάρτα',
		bank: 'Τραπεζική κατάθεση',
	},
	eventDetails: {
		title: 'Ορκομωσία',
		createTitle: 'Δημιουργία Ορκομωσίας',
		labelSchoolAndDate: 'Τμήμα και Ημερομηνία',
		labelEventStatistics: 'Στατιστικά ορκομωσίας',
		numberPeople: 'Αριθμός ατόμων',
		numberPeoplePlaceholder: 'Αριθμός ατόμων που ορκίστηκαν',
		numberPhotos: 'Αριθμός φωτογραφιών',
		otherStudios: 'Άλλα συνεργεία',
		numberPhotosPlaceholder: 'Αριθμός άλλων συνεργείων',
		otherStudiosPlaceholder: 'Πόσα άλλα συνεργεία ηταν παρόντα',
		filePrice: 'Τιμή αρχείου €',
		filePriceExpl: 'Τιμή ανα αρχείο για αυτή την Ορκομωσία',
	},
	ordersView: {
		createOrderTitle: 'Δημιουργία παραγγελίας',
		viewOrderTitle: 'Παραγγελία',
		paymentDetails: {
			title: 'Πληρωμές',
			total: 'Συνολο',
			creationDate: 'Ημερομηνία Καταχώρησης',
		},
	},
};

const AllLang = {
	en: en,
	gr: gr,
};

const Lang = AllLang[SystemSettingsDefaults.defaultLocale];

export default Lang;
