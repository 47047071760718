import { Dimension } from '../../variables/general';
import SchoolSelect from '../SchoolSelect';
import EventSelect from '../EventSelect';
import React from 'react';
import * as yup from 'yup';

export default function schoolEventPanel({
	form,
	setForm,
	schoolPickerId = 'schoolId',
	eventPickerId = 'eventId',
}) {
	const { complementaryOf } = form;
	const isComplementary = !!complementaryOf;

	const updateState = (newState) => {
		const { schoolId: oldSchoolId } = form;
		const { schoolId: newSchoolId } = newState;

		if (oldSchoolId && oldSchoolId !== '') {
			if (oldSchoolId !== newSchoolId) {
				delete form.eventId;
				delete newState.eventId;
			}
		}

		return setForm({ ...form, ...newState });
	};

	return [
		{
			id: schoolPickerId,
			size: Dimension.formItem,
			yup: yup.string().required(),
			renderElement: () => (
				<SchoolSelect
					disabled={isComplementary}
					required
					id={schoolPickerId}
					value={form[schoolPickerId]}
					onChange={({ target }) =>
						updateState({ [schoolPickerId]: target.value })
					}
				/>
			),
		},
		{
			id: eventPickerId,
			size: Dimension.formItem,
			yup: yup.string().required(),
			renderElement: () => (
				<EventSelect
					disabled={isComplementary}
					required
					id={eventPickerId}
					filterBySchoolId={form[schoolPickerId]}
					value={form[eventPickerId]}
					onChange={({ target }) =>
						setForm({ ...form, [eventPickerId]: target.value })
					}
				/>
			),
		},
	];
}
