import Lang from '../../variables/i18n';
import CustomTable from '../Table/Table';
import React, { useState } from 'react';
import { asArray } from '../useSupercore';
import {
	concatSchool,
	formatName,
	formatSerialCount,
	parseDate,
} from '../../utils';
import GenericActions from '../GenericActions';
import { AddMoneyIcon } from '../../variables/AppIcons';
import DeleteModal from '../DeleteModal';
import OrderBrief from '../OrderBrief';
import PaymentModal from '../PaymentModal';
import SCLink from '../SCLink';
import { SCModules } from '../supercore/api';
import useForceRefresh from '../useForceRefresh';
import { useFiltersStore } from '../../store/filters';
import { applyFilters } from '../FiltersToolbar';
import { Check } from '@material-ui/icons';

const filterDefinitions = {
	orders_pending_work: (store) =>
		store.filter(({ taskPrePrintDone }) => !taskPrePrintDone),
	orders_awaiting_pickup: (store) =>
		store.filter(({ taskPrePrint }) => !taskPrePrint),
	orders_pending_photobook: (store) =>
		store.filter(
			({ taskPhbSentPrint = false, taskPhbPrintDone = false }) =>
				taskPhbSentPrint && !taskPhbPrintDone
		),
	orders_complete: (store) => store.filter(({ isComplete }) => isComplete),
	orders_paid: (store) => store.filter(({ isPaid }) => isPaid),
	orders_not_paid: (store) => store.filter(({ isPaid }) => !isPaid),
	orders_eleven_only: (store) =>
		store.filter(({ id, isEleven = false }) => {
			console.group(`el filter: ${id}`);
			console.log(isEleven);
			console.groupEnd();
			return isEleven;
		}),
	orders_eleven_exclude: (store) =>
		store.filter(({ id, isEleven = false }) => {
			console.group(`non-el filter: ${id}`);
			console.log(!isEleven);
			console.groupEnd();
			return !isEleven;
		}),
	orders_deficient_only: (store) =>
		store.filter(({ isDeficient = false }) => isDeficient),
	orders_search: (store, searchTerm) =>
		store.filter((o) => {
			const fields = [
				'serialCount',
				'schoolName',
				'deptName',
				'instName',
				'orderDate',
				'eventDate',
				'tel',
				'firstName',
				'lastName',
			];
			const order = {
				...o,
				serialCount: formatSerialCount(o),
				eventDate: parseDate(o.eventDate),
				orderDate: parseDate(o.orderDate),
			};
			let partialMatch = false;

			if (!searchTerm || searchTerm.trim() === '') {
				return true;
			}

			for (let i = 0; i < fields.length && !partialMatch; i++) {
				const val =
					fields[i] === 'serialCount'
						? formatSerialCount(order)
						: `${order[fields[i]]}`.toLowerCase();
				partialMatch |= val.indexOf(searchTerm.toLowerCase()) > -1;
			}

			return partialMatch;
		}),
};

export default function OrdersTable({
	pending,
	store = {},
	handleDelete,
	useFilters = false,
}) {
	const [deleteModal, setDeleteModal] = useState(undefined);
	const [txModal, setTxModal] = useState(undefined);
	const { setForceRefresh } = useForceRefresh();
	const { state: filterState } = useFiltersStore('orders');

	const entries = applyFilters(
		asArray(store),
		useFilters,
		filterState,
		filterDefinitions
	).map(
		({
			id,
			schoolName,
			deptName,
			instName,
			orderDate,
			eventId,
			eventDate,
			price = 0,
			amountPaid = 0,
			isPaid,
			tel,
			serialCount,
			isEleven,
			isDeficient,
			...rest
		}) => [
			<SCLink
				module={SCModules.orders}
				entryId={id}
				label={formatSerialCount({ serialCount })}
			/>,
			concatSchool({ schoolName, instName, name: deptName }),
			<SCLink module={SCModules.events} entryId={eventId} label={eventDate} />,
			new Date((orderDate || {})._seconds * 1000).toLocaleDateString('el-GR'),
			formatName(rest || {}),
			`${Lang.currencySymbol.euro} ${price}`,
			`${Lang.currencySymbol.euro} ${price - amountPaid}`,
			isEleven ? <Check /> : '',
			isDeficient ? <Check /> : '',
			<GenericActions
				id={id}
				view={'orders'}
				handleDelete={handleDelete && (() => setDeleteModal(id))}
				actions={[
					{
						label: 'Νέα Πληρωμή',
						Icon: AddMoneyIcon,
						onClick: () => setTxModal(id),
						disabled: isPaid,
						onComplete: () => setForceRefresh(true),
					},
				]}
			/>,
		]
	);

	const headers = [
		'', //serialCount
		Lang.school,
		Lang.eventDate,
		Lang.orderDate,
		Lang.client,
		Lang.price,
		Lang.amountOwed,
		'Έντεκα',
		'Έχει ελλείψεις',
		'', //action buttons
	];

	return (
		<>
			<CustomTable
				pending={pending}
				empty={!entries || entries.length === 0}
				tableHead={headers}
				tableData={entries}
			/>
			<PaymentModal
				open={!!txModal}
				orderId={txModal}
				create
				onClose={() => setTxModal(undefined)}
			/>
			<DeleteModal
				open={!!deleteModal}
				onClick={() => handleDelete(deleteModal)}
				onClose={() => setDeleteModal(undefined)}
			>
				<OrderBrief
					{...((store || {})[deleteModal] || {})}
					orderId={deleteModal}
				/>
			</DeleteModal>
		</>
	);
}
