import { CreateButton, SaveButton } from '../CommonButtons';
import React from 'react';
import { useNavigate } from 'react-router';

export default function PersistButton({
	create,
	icon,
	pending,
	setPending,
	urlRedirectPrefix,
	setNtfTxt,
	doCreate,
	doSave,
	form,
	isValid,
}) {
	const navigate = useNavigate();

	const onSaveHandler = async () => {
		try {
			typeof setPending === 'function' && setPending(true);
			const { fail, error } = await doSave(form);
			fail && typeof setNtfTxt === 'function' && setNtfTxt(error);
		} finally {
			typeof setPending === 'function' && setPending(false);
		}
	};

	const onCreateHandler = async () => {
		try {
			typeof setPending === 'function' && setPending(true);
			const { id, fail, error } = await doCreate(form);
			fail
				? typeof setNtfTxt === 'function' && setNtfTxt(error)
				: navigate(`${urlRedirectPrefix}${id}`);
		} finally {
			typeof setPending === 'function' && setPending(false);
		}
	};

	return create ? (
		<CreateButton
			icon={icon}
			disabled={pending}
			onClick={onCreateHandler}
			isValid={isValid}
			onPending={setPending}
		/>
	) : (
		<SaveButton
			disabled={pending}
			onClick={onSaveHandler}
			onPending={setPending}
			isValid={isValid}
		/>
	);
}
