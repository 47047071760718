import React from 'react';
import GridItem from '../components/Grid/GridItem';

export const Dimension = {
	md: {
		xs: 12,
		sm: 12,
		md: 10,
	},
	pageTwoThirds: {
		md: 8,
	},
	pageOneThird: {
		md: 4,
	},
	pageHalf: {
		md: 6,
	},
	taskColumn: {
		xs: 3,
	},
	formItem: {
		xs: 6,
	},
	formItemOneThird: {
		xs: 4,
	},
	formItemTwoThirds: {
		xs: 8,
	},
	formItemFullWidth: {
		xs: 12,
	},
};

export const FullWidth = ({ children, ...props }) => (
	<GridItem {...Dimension.formItemFullWidth} {...props}>
		{children}
	</GridItem>
);

export const HalfWidth = ({ children, ...props }) => (
	<GridItem {...Dimension.formItem} {...props}>
		{children}
	</GridItem>
);

export const OneThird = ({ children, ...props }) => (
	<GridItem {...Dimension.formItemOneThird} {...props}>
		{children}
	</GridItem>
);

export const TwoThirds = ({ children, ...props }) => (
	<GridItem {...Dimension.formItemTwoThirds} {...props}>
		{children}
	</GridItem>
);

export const getTodayShiftId = (shift = '') => {
	const now = new Date();
	const year = now.getFullYear();
	const thisMonth = now.getMonth() + 1;
	const day = now.getDate();
	return `${year}-${thisMonth}-${day}-${shift}`;
};
