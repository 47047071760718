import Button from '@material-ui/core/Button';
import Lang from '../../variables/i18n';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import GenericForm from '../GenericForm';
import { getTodayShiftId } from '../../variables/general';
import Ntf from '../Ntf';

export default function ManualAmountDialog({
	open,
	entity,
	onClose,
	pending,
	doSave,
	setPending,
}) {
	const [ntfTxt, setNtfTxt] = useState();
	// const create = entity === true;
	const [form, setForm] = useState({
		id: '',
		amount: 0,
		shift: '',
		...(entity === true ? {} : entity),
	});
	const { shift } = form;

	useEffect(() => {
		setForm({ ...form, id: getTodayShiftId(shift) });
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shift]);

	const saveHandler = async () => {
		try {
			typeof setPending === 'function' && setPending(true);
			const { fail, error } = await doSave(form);
			fail && setNtfTxt(error);
			!fail && onClose();
		} finally {
			typeof setPending === 'function' && setPending(false);
		}
	};

	return (
		<Dialog open={open} onClose={() => onClose(false)}>
			<DialogTitle id={'add-entry-modal'}>Κλείσιμο ταμείου</DialogTitle>
			<DialogContent>
				<Ntf messages={ntfTxt} close onClose={() => setNtfTxt(undefined)} />
				<GenericForm
					formState={form}
					setFormState={setForm}
					fields={[
						{
							id: 'shift',
							type: 'select',
							disabled: pending,
							defaultSelectLabel: 'Επιλογή βάρδιας',
							options: [
								{ value: '1', label: 'Βάρδια 1' },
								{ value: '2', label: 'Βάρδια 2' },
							],
						},
						{
							id: 'amount',
							type: 'number',
							label: 'Επιβεβαίωση Πραγματικού',
							disabled: pending,
						},
					]}
				/>
			</DialogContent>
			<DialogActions>
				<Button disabled={pending} onClick={onClose} autoFocus>
					{Lang.cancel}
				</Button>
				<Button
					onClick={saveHandler}
					color={'primary'}
					disabled={pending || form.manualAmountShift === ''}
				>
					Επιβεβαίωση
				</Button>
			</DialogActions>
		</Dialog>
	);
}
