import { SystemProperties } from '../../variables/sysSettings';

const baseurl =
	window.location.host.indexOf('localhost') === 0
		? 'http://localhost:5001/fotomada-dev/us-central1'
		: SystemProperties.baseUrl.val;

// const baseurl = ''; //SystemProperties.baseUrl.val;

// console.log(baseurl);

export const SCModules = {
	content: 'content-module',
	comments: 'content-module/comment',
	blog: 'content-module/blog',
	notifications: 'notifications-module',
	users: 'users-module',
	userInvitations: 'userInvites-module',
	history: 'history-module',
	orders: 'orders-module',
	events: 'events-module',
	schools: 'schools-module',
	vault: 'vault-module',
	vaultTotals: 'vaultTotals-module',
	manualAmounts: 'manualAmounts-module',
	tasks: 'tasks-module',
	passwordReset: 'passwordReset-module',
};

const makeParams = (params = []) => {
	// params = { ...params, _cb: `${Date.now()}` };
	return params.length
		? '?'
		: '' +
				Object.keys(params)
					.map((key) => `${key}=${encodeURIComponent(params[key])}`)
					.join('&');
};

let firebaseRef;

export const initApi = (firebase) => {
	firebaseRef = firebase;
};

export const doPost = async ({
	module,
	body,
	params,
	path,
	headers,
	authorized = true,
}) =>
	await makeRequest(module, body, params, path, headers, 'POST', authorized);

export const doGet = async ({
	module,
	body,
	params,
	path,
	headers,
	authorized = true,
}) => await makeRequest(module, body, params, path, headers, 'GET', authorized);

export const doPut = async ({
	module,
	body,
	params,
	path,
	headers,
	authorized = true,
}) => await makeRequest(module, body, params, path, headers, 'PUT', authorized);

export const doDelete = async ({ module, body, params, path, headers }) =>
	await makeRequest(module, body, params, path, headers, 'DELETE', true);

const makeRequest = async (
	module,
	body,
	params,
	path,
	headers,
	method,
	authorized = true
) =>
	await fetch(
		`${baseurl}/${module ? module + '/' : ''}${
			path ? path + '/' : ''
		}${makeParams(params)}`,
		{
			headers: {
				'content-type': 'application/json',
				accept: 'application/json',
				// mode: 'no-cors',
				// 'Access-Control-Allow-Origin': '*',
				// 'Access-Control-Allow-Headers': ['GET', 'PUT', 'POST','DELETE','OPTIONS','HEAD'],
				authorization: authorized && `Bearer ${await firebaseRef.getToken()}`,
				...headers,
			},
			method,
			body: body && JSON.stringify(body),
		}
	);
