import React, { useState } from 'react';
import Lang from '../../variables/i18n';
import CustomTable from '../Table/Table';
import useSupercore, { asArray } from '../useSupercore';
import { SCModules } from '../supercore/api';
import UserAvatar from '../UserAvatar';
import GenericActions from '../GenericActions';
import TimeField from '../TimeField/TimeField';
import DeleteModal from '../DeleteModal';
import SCLink from '../SCLink';
import { BankChip, CardChip, CashChip } from '../CommonChips';
import { CreateButton } from '../CommonButtons';
import { Euro } from '@material-ui/icons';

function SourceChip({ source }) {
	switch (source) {
		case 'cash':
			return <CashChip />;
		case 'card':
			return <CardChip />;
		case 'bank':
			return <BankChip />;
		default:
			return '?';
	}
}

function AmountDisplay({ type, amount }) {
	return `${type === 'in' ? '+' : '-'} ${amount}`;
}

export default function TransactionsTable({ filter, onEachRow, showCreate }) {
	const { field: filterField, value: filterValue } = filter || {};
	const { store, status, error, handleDelete } = useSupercore({
		supercoreApiName: SCModules.vault,
		entryId: filter ? `${filterField}/${filterValue}` : undefined,
	});

	const [deleteModal, setDeleteModal] = useState(undefined);

	const entries = (asArray(store) || [])
		.filter((entry) => (filter ? entry[filterField] === filterValue : true))
		.map((entry) => {
			typeof onEachRow === 'function' && onEachRow(entry);
			return entry;
		})
		.sort(
			({ _lastEditMS: lastEditA = {} }, { _lastEditMS: lastEditB = {} }) =>
				lastEditB._seconds - lastEditA._seconds
		)
		.map(
			({
				id,
				_lastEditBy: uid,
				_lastEditMS,
				orderId,
				source,
				amount = 0,
				type = 'in',
			}) => [
				<UserAvatar uid={uid} showName />,
				<TimeField datetime={_lastEditMS} />,
				orderId ? (
					<SCLink
						module={SCModules.orders}
						entryId={orderId}
						label={Lang.order}
					/>
				) : (
					''
				),
				<SourceChip source={source} />,
				<AmountDisplay type={type} amount={amount} />,
				<GenericActions
					view={'cash'}
					id={id}
					handleDelete={() => setDeleteModal(id)}
				/>,
			]
		)
		.reduce((acc, curr) => acc.push(curr) && acc, []);

	return (
		<>
			{showCreate ? (
				<CreateButton icon={<Euro />} title={Lang.vaultView.newTransaction} />
			) : undefined}
			<CustomTable
				pending={status === 'pending'}
				empty={status === 'success' && entries.length === 0}
				error={error}
				tableHead={[
					Lang.vaultView.addedBy, //user avatar
					Lang.datetime,
					Lang.vaultView.order,
					Lang.vaultView.source,
					Lang.amount + ' €',
					'', // actions toolbar
				]}
				tableData={entries}
			/>
			<DeleteModal
				open={!!deleteModal}
				onClick={() => handleDelete(deleteModal)}
				onClose={() => setDeleteModal(undefined)}
			>
				{deleteModal && (
					<>
						Τύπος: <b>{store[deleteModal].type === 'in' ? 'Έσοδα' : 'Έξοδα'}</b>
						<br />
						Ποσό: <b>{store[deleteModal].amount}</b>
						<br />
						Πηγή: <b>{store[deleteModal].source}</b>
					</>
				)}
			</DeleteModal>
		</>
	);
}
