import Chip from '@material-ui/core/Chip';
import Lang from '../../variables/i18n';
import Paper from '@material-ui/core/Paper/Paper';
import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import { AddImageButton, CopyToClipboardButton } from '../CommonButtons';
import CustomInput from '../CustomInput/CustomInput';
import { Dimension } from '../../variables/general';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		listStyle: 'none',
		padding: theme.spacing(0.5),
		margin: 0,
	},
	chip: {
		margin: theme.spacing(0.5),
	},
}));

const ItemsList = ({
	id,
	formState,
	setFormState,
	placeholder = '',
	labelText = Lang.add,
	emptyText = 'Λίστα άδεια!',
	copyToClipboard = false,
	onClick,
	onDelete,
	extraButton,
}) => {
	const classes = useStyles();

	const defaultOnClick = () => {
		const val = document.getElementById(id).value || '';

		if (val.trim().length === 0) {
			return;
		}

		setFormState({
			...formState,
			[id]: [...new Set([...formState[id], val])],
		});
	};

	const copyToClipboardFn = async () => {
		await navigator.clipboard.writeText(formState[id].join(' '));
	};

	const defaultOnDelete = (item) =>
		setFormState({
			...formState,
			[id]: formState[id].filter((t) => t !== item),
		});

	const chips = (formState[id] || []).map((item) => (
		<li key={item}>
			<Chip
				label={item}
				className={classes.chip}
				onDelete={() => (onDelete && onDelete(item)) || defaultOnDelete(item)}
			/>
		</li>
	));

	return (
		<GridContainer>
			<GridItem xs={12 - (copyToClipboard ? 1 : 0) - (extraButton ? 1 : 0)}>
				<Paper component="ul" className={classes.root}>
					{chips.length ? chips : emptyText}
				</Paper>
			</GridItem>
			{extraButton && <GridItem xs={1}>{extraButton}</GridItem>}
			{copyToClipboard && (
				<GridItem xs={1}>
					<CopyToClipboardButton
						onClick={copyToClipboardFn}
						tooltipLabel="Αντιγραφή λίστας"
						disabled={!(formState[id] || []).length}
					/>
				</GridItem>
			)}
			<GridItem {...Dimension.formItem}>
				<CustomInput
					labelText={labelText}
					placeholder={placeholder}
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						id,
						endAdornment: (
							<InputAdornment position="end">
								<AddImageButton onClick={onClick || defaultOnClick} />
							</InputAdornment>
						),
					}}
				/>
			</GridItem>
		</GridContainer>
	);
};

ItemsList.propTypes = {
	id: PropTypes.string,
	placeholder: PropTypes.string,
	labelText: PropTypes.string,
	emptyText: PropTypes.string,
	formState: PropTypes.object,
	setFormState: PropTypes.func,
	onClick: PropTypes.func,
	onDelete: PropTypes.func,
	copyToClipboard: PropTypes.bool,
};

export default ItemsList;
