import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { customInputTableStyle } from '../../assets/jss/material-dashboard-react/components/customInputStyle';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';

const useStyles = makeStyles(customInputTableStyle);

export default function CustomFormControl({
	label,
	fullWidth = true,
	htmlFor,
	error,
	required,
	...props
}) {
	const classes = useStyles();

	return (
		<FormControl
			error={error}
			fullWidth={fullWidth}
			className={classes.formControl}
		>
			<InputLabel className={classes.labelRoot} htmlFor={htmlFor}>
				{required ? (
					<>
						<span className={classes.required}>*</span> {label}
					</>
				) : (
					label
				)}
			</InputLabel>
			{props.children}
		</FormControl>
	);
}
