import { FormItemTypes } from '../FormItem';
import { Dimension } from '../../variables/general';
import OrderTaskAuthor from '../OrderTaskAuthor';
import React from 'react';

export default function OrderTask(
	id,
	label,
	form,
	create = false,
	isStartDone = false,
	display = true
) {
	const taskStartId = id;
	const taskStartById = `${id}By`;
	const taskDoneId = `${id}Done`;
	const taskDoneById = `${id}DoneBy`;
	/*

	const a=!!(form.phbSize && form.phbSize !== 'none')

	console.group(`ordertask ${id} - ${label}`)
	console.log(`create: ${create}`)
	console.log(`isStartDone: ${isStartDone}`)
	console.log(`display: ${display}`)
	console.log(`form['phbSize']: ${form['phbSize']}`)
	console.log(`!!form['phbSize']: ${a}`)

	console.groupEnd()
*/

	const taskListForDone = isStartDone
		? [
				{
					id: taskDoneId,
					type: FormItemTypes.taskcheckbox,
					label: `Ολοκληρώθηκε: ${label}`,
					display: display && !create && form[taskStartId],
					disabled: !form[taskStartId],
				},
				{
					id: taskDoneById,
					size: Dimension.formItem,
					display: display && !create,
					renderElement: () => (
						<OrderTaskAuthor form={form} taskId={taskDoneId} />
					),
				},
		  ]
		: [];

	return [
		{
			id: taskStartId,
			type: FormItemTypes.taskcheckbox,
			label: isStartDone ? `Ξεκίνησε: ${label}` : label,
			display: display && !create,
		},
		{
			id: taskStartById,
			size: Dimension.formItem,
			display: display && !create,
			renderElement: () => <OrderTaskAuthor form={form} taskId={taskStartId} />,
		},
		...taskListForDone,
	];
}
