import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// @material-ui/icons
import Button from './../../components/CustomButtons/Button.js';

import styles from './../../assets/jss/material-dashboard-react/components/headerStyle.js';
import { useNavigate } from 'react-router';
import SystemSettingsDefaults from '../../variables/sysSettings';

const useStyles = makeStyles(styles);

/**
 * Navbar for anonymous users
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export default function AnonNavbar(props) {
	const classes = useStyles();
	const navigate = useNavigate();
	const { color } = props;
	const appBarClasses = classNames({
		[' ' + classes[color]]: color,
	});
	return (
		<AppBar className={classes.appBar + appBarClasses}>
			<Toolbar className={classes.container}>
				<div className={classes.flex}>
					<Button onClick={() => navigate('/login')}>Log In</Button>
					{SystemSettingsDefaults.allowSelfSignup && (
						<Button onClick={() => navigate('/register')}>
							Create Account
						</Button>
					)}
				</div>
			</Toolbar>
		</AppBar>
	);
}

AnonNavbar.propTypes = {
	color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
	rtlActive: PropTypes.bool,
	routes: PropTypes.arrayOf(PropTypes.object),
};
