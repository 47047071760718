import React, { useState } from 'react';
import Lang from '../../variables/i18n';
import { Event } from '@material-ui/icons';
import { Navigate } from 'react-router';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import CustomTable from '../../components/Table/Table';
import EventDetails from '../../components/EventDetails';
import CardFooter from '../../components/Card/CardFooter';
import { CreateButton } from '../../components/CommonButtons';
import GenericActions from '../../components/GenericActions';
import { asArray } from '../../components/useSupercore';
import { SCModules } from '../../components/supercore/api';
import DeleteModal from '../../components/DeleteModal';
import EventBrief from '../../components/EventBrief';
import CardTitle from '../../components/CardTitle';
import useLoadView from '../../utils/useLoadView';

const EventsView = () => {
	const [deleteModal, setDeleteModal] = useState(undefined);
	const [pending, setPending] = useState(false);
	const { state, doDelete } = useLoadView(SCModules.events, setPending);

	const entries = asArray(state)
		.filter((o) => !Array.isArray(o))
		.map(
			({
				id,
				date = {},
				instName,
				deptName,
				schoolName,
				numberPeople,
				numberPhotos,
				ordersCount = 0,
			}) => [
				instName,
				schoolName,
				deptName,
				new Date(date._seconds * 1000).toLocaleDateString('el-GR'),
				numberPeople,
				numberPhotos,
				ordersCount,
				<GenericActions
					view
					id={id}
					handleDelete={() => setDeleteModal(id)}
					deleteDisabled={ordersCount > 0}
				/>,
			]
		);

	return (
		<Card>
			<CardHeader>
				<CardTitle title={EventsRoute.name} icon={<Event />}>
					<CreateButton />
				</CardTitle>
			</CardHeader>
			<CardBody>
				<CustomTable
					pending={pending}
					empty={entries && entries.length === 0}
					tableHead={[
						'Ίδρυμα',
						'Σχολή',
						'Τμήμα',
						Lang.date,
						'Αρ. Ατόμων',
						'Αρ. Φωτογραφιών',
						'Αρ. Παραγγελιών',
						'', // actions toolbar
					]}
					tableData={entries}
				/>
			</CardBody>
			<CardFooter>
				<span />
			</CardFooter>
			<DeleteModal
				open={!!deleteModal}
				onClick={() => {
					setPending(true);
					doDelete(deleteModal).finally(() => setPending(false));
				}}
				onClose={() => setDeleteModal(undefined)}
			>
				<EventBrief id={deleteModal} />
			</DeleteModal>
		</Card>
	);
};

const EventsRoute = {
	path: 'events',
	name: Lang.events,
	icon: Event,
	children: [
		{ path: '/', element: <EventsView /> },
		{ path: 'create', element: <EventDetails create /> },
		{ path: 'view', element: <Navigate to="../" /> },
		{ path: 'view/:eventId', element: <EventDetails /> },
	],
};

export default EventsRoute;
