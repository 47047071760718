import app from 'firebase/app';
import firebase from 'firebase';
// import 'firebase/auth';
import { firebaseConfig } from '../../firebase.config';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../store/store';

export const useAuthorisedPage = () => {
	const { user, setUser } = useContext(AppContext);
	const isAuth = user || false;

	useEffect(() => {
		!app.apps.length && app.initializeApp(firebaseConfig);
		const auth = app.auth();
		auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
			auth.onAuthStateChanged((user) => setUser(user || false));
		});
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const signIn = (username, password) =>
		app
			.auth()
			.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
			.then(() => app.auth().signInWithEmailAndPassword(username, password));

	return {
		isAuth,
		user,
		setUser,
		signIn,
		signOut: app.auth().signOut,
	};
};

export class Firebase {
	constructor() {
		if (!app.apps.length) {
			app.initializeApp(firebaseConfig);
		}
		/*
		this.auth = app.auth();
		this.auth.onAuthStateChanged(
			(user) => typeof setUser === 'function' && setUser(user)
		);
*/
		if (`${window.location}`.indexOf('http://localhost') === 0) {
			// app.connectAuthEmulator();
			app.auth().useEmulator('http://localhost:9099');
		}
	}

	registerNewUser = async (email, password, displayName) => {
		try {
			const response = await fetch(
				`https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net/auth-users`,
				{
					method: 'POST',
					mode: 'no-cors',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						email: email,
						password: password,
						displayName: displayName,
					}),
				}
			);

			return response.ok;
		} catch (e) {
			console.log(e);
			return false;
		}
	};

	signInUser = async (email, password) =>
		await app.auth().signInWithEmailAndPassword(email, password);

	signOutUser = () =>
		app
			.auth()
			.signOut()
			.then(() => localStorage.clear());

	resetPassword = (email) => app.auth().sendPasswordResetEmail(email);

	updatePassword = (password) =>
		app.auth().currentUser.updatePassword(password);

	getCurrentUser = () => app.auth().currentUser;

	getToken = async () =>
		await (app.auth().currentUser && app.auth().currentUser.getIdToken(true));
}

const useCurrentUser = () => {
	const [user, setUser] = useState();
	const fb = new Firebase(setUser);

	useEffect(() => {
		let mounted = true;
		mounted && setUser(fb.getCurrentUser());

		return () => {
			mounted = false;
		};
	}, [user, fb]);

	return [user, setUser, fb];
};

export default useCurrentUser;
