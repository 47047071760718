import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useStore } from '../useSupercore';
import { SCModules } from '../supercore/api';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import { BackButton } from '../CommonButtons';
import Card from '../Card/Card';
import GenericForm from '../GenericForm';
import FormLabel from '@material-ui/core/FormLabel';
import { genericAddressFields } from '../GenericAddressForm';
import { School } from '@material-ui/icons';
import { Dimension } from '../../variables/general';
import * as yup from 'yup';
import GenericBackdrop from '../GenericBackdrop';
import CardTitle from '../CardTitle';
import { concatSchool } from '../../utils';
import {
	ChipContainer,
	EventsCountChip,
	OrdersCountChip,
} from '../CommonChips';
import { actionShorthand } from '../../actions';
import Ntf from '../Ntf';
import PersistButton from '../PersistButton';
import { useQueryDocument } from '../ReactQueryUtils';

export default function SchoolDetails({ create = false }) {
	const { eventId: entryId } = useParams();
	const [pendingState, setPending] = useState(true);
	const [isValid, setIsValid] = useState(false);
	const [ntfTxt, setNtfTxt] = useState();
	const { dispatch } = useStore(SCModules.schools);
	const { doSave, doCreate, doDelete } = actionShorthand({
		dispatch,
		module: SCModules.schools,
		id: entryId,
	});

	const [data, setData] = useState({});
	// eslint-disable-next-line
	const [result, isLoading] = useQueryDocument(
		SCModules.schools,
		entryId,
		(data, e) => {
			setData(data);
			setPending(false);
		},
		{
			enable: !create,
		}
	);

	const pending = pendingState || isLoading;

	const { eventsCount = 0, ordersCount = 0 } = data;

	return (
		<Card>
			<CardHeader>
				<CardTitle
					id={entryId}
					icon={<School />}
					title={create ? 'Δημιουργία Σχολής' : `Σχολή ${concatSchool(data)}`}
					handleDelete={() => doDelete(entryId)}
				/>
				<ChipContainer show={!create}>
					<EventsCountChip eventsCount={eventsCount} />
					<OrdersCountChip ordersCount={ordersCount} />
				</ChipContainer>
			</CardHeader>
			<CardBody>
				<Ntf messages={ntfTxt} close onClose={() => setNtfTxt(undefined)} />
				{create && (
					<React.Fragment>
						<FormLabel>Δημιουργία νέου Τμήματος</FormLabel>
						<p>
							Το κάθε Τμήμα υπάγεται σε μια Σχολή, που υπάγεται σε ενα Ίδρυμα
						</p>
					</React.Fragment>
				)}
				<GenericBackdrop open={pending} />
				<GenericForm
					setIsValid={setIsValid}
					formState={data}
					setFormState={setData}
					fields={[
						{
							id: 'shortName',
							label: 'Συντομονυμία',
							placeholder: 'Σύντομο όνομα',
							size: Dimension.formItemOneThird,
							readOnly: false,
							yup: yup.string(),
						},
						{
							id: 'sep',
							type: 'formLabel',
							label: '',
						},
						{
							id: 'instName',
							label: 'Ίδρυμα',
							placeholder: 'Όνομα ιδρύματος, πχ. "ΑΠΘ"',
							size: Dimension.formItemOneThird,
							readOnly: !create,
							yup: yup.string().required(),
						},
						{
							id: 'schoolName',
							label: 'Σχολή',
							placeholder: 'Όνομα σχολής, πχ. "Νομική"',
							size: Dimension.formItemOneThird,
							readOnly: !create,
							yup: yup.string().required(),
						},
						{
							id: 'name',
							label: 'Τμήμα',
							placeholder: 'Όνομα τμήματος, πχ. "Διεθνών Σπουδών"',
							size: Dimension.formItemOneThird,
							readOnly: !create,
							yup: yup.string().required(),
						},
						{
							id: 'sl1',
							type: 'formLabel',
							label: 'Διεύθυνση Τμήματος',
						},
						...genericAddressFields,
					]}
				/>
			</CardBody>

			<CardFooter>
				<BackButton />
				<PersistButton
					form={data}
					create={create}
					doCreate={doCreate}
					doSave={doSave}
					urlRedirectPrefix={'/admin/schools/view/'}
					icon={<School />}
					pending={pending}
					setPending={setPending}
					setNtfTxt={setNtfTxt}
					isValid={isValid}
				/>
			</CardFooter>
		</Card>
	);
}
