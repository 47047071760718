import React from 'react';
import CustomTabs from '../CustomTabs/CustomTabs';
import PropTypes from 'prop-types';
import AuditHistory from '../AuditHistory';
import GenericComments from '../GenericComments';
import TransactionsTable from '../TransactionsTable';

export default function GenericAdditionalInfo({
	collection,
	id,
	show,
	showComments = false,
	showHistory = true,
}) {
	const tabs = [];

	collection === 'orders' &&
		tabs.push(
			/*			{
				tabName: 'Εργασίες',
				tabContent: <TasksTable parentId={id} />,
			},*/
			{
				tabName: 'Transactions',
				tabContent: (
					<TransactionsTable filter={{ field: 'orderId', value: id }} />
				),
			}
		);

	show &&
		showComments &&
		tabs.push({
			tabName: 'Comments',
			tabContent: <GenericComments collection={collection} id={id} />,
		});

	show &&
		showHistory &&
		tabs.push({
			tabName: 'History',
			tabContent: <AuditHistory collection={collection} id={id} />,
		});

	return !show ? null : <CustomTabs title="Additional info" tabs={tabs} />;
}

GenericAdditionalInfo.propTypes = {
	id: PropTypes.string.isRequired,
	collection: PropTypes.string.isRequired,
	show: PropTypes.bool.isRequired,
};
