import { useStore } from '../useSupercore';
import { SCModules } from '../supercore/api';
import GridItem from '../Grid/GridItem';
import { Dimension } from '../../variables/general';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardTitle from '../CardTitle';
import Lang from '../../variables/i18n';
import CardBody from '../Card/CardBody';
import GenericBackdrop from '../GenericBackdrop';
import GenericForm from '../GenericForm';
import CardFooter from '../Card/CardFooter';
import { BackButton } from '../CommonButtons';
import GenericAdditionalInfo from '../GenericAdditionalInfo';
import GridContainer from '../Grid/GridContainer';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import Person from '@material-ui/icons/Person';
import { actionShorthand } from '../../actions';
import useMountedHook from '../../utils/useMountedHook';
import PersistButton from '../PersistButton';
import { Group } from '@material-ui/icons';
import Ntf from '../Ntf';

const passwordValid = ({ password = '', passwordAgain = '' }) =>
	password === passwordAgain && password.length >= 8;

export default function UserDetails({ create = false }) {
	const { entryId: uid } = useParams();
	const [pending, setPending] = useState(false);
	const { state, dispatch } = useStore(SCModules.users);
	// const [forceRefresh, setForceRefresh] = useState(0);
	const [ntfTxt, setNtfTxt] = useState();
	const { doLoadUser, doSave, doCreate } = actionShorthand({
		dispatch,
		module: SCModules.users,
		id: uid,
	});

	useMountedHook(
		(mounted) => {
			if (create) {
				return;
			}

			setPending(true);
			doLoadUser().finally(() => mounted && setPending(false));

			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[uid, create]
	);

	const [form, setForm] = useState((!create && state[uid]) || {});
	const [isValid, setIsValid] = useState(false);

	const formFields = [
		{
			id: 'email',
			placeholder: 'Χρησιμοποιείται κατα τη σύνδεση στο σαιτ',
			label: Lang.email,
			required: true,
		},
		{
			id: 'displayName',
			label: 'Display name',
			required: true,
		},
	];

	create &&
		formFields.push(
			{
				id: 'password',
				label: 'Προσωρινός κωδικός',
				required: true,
				type: 'password',
			},
			{
				id: 'passwordAgain',
				label: 'Επιβεβαίωση κωδικού',
				required: true,
				type: 'password',
			}
		);

	return (
		<GridContainer>
			<GridItem {...Dimension.md}>
				<Card>
					<CardHeader>
						<CardTitle
							id={uid}
							create={create}
							// handleDelete={handleDelete}
							createTitle={`Create ${Lang.user}`}
							editTitle={form.displayName}
							icon={<Person />}
						/>
					</CardHeader>
					<CardBody>
						<Ntf messages={ntfTxt} close onClose={() => setNtfTxt(undefined)} />
						<GenericBackdrop open={pending} />
						<GenericForm
							setFormState={setForm}
							formState={form}
							setIsValid={(valid) => setIsValid(valid && passwordValid(form))}
							fields={formFields}
						/>
					</CardBody>
					<CardFooter>
						<BackButton />
						<PersistButton
							form={form}
							create={create}
							doCreate={doCreate}
							doSave={doSave}
							urlRedirectPrefix={'/admin/users/view/'}
							icon={<Group />}
							pending={pending}
							setPending={setPending}
							setNtfTxt={setNtfTxt}
							isValid={isValid}
						/>
					</CardFooter>
				</Card>
			</GridItem>
			<GridItem {...Dimension.md}>
				<GenericAdditionalInfo show={!create} collection={'users'} id={uid} />
			</GridItem>
		</GridContainer>
	);
}
