import React from 'react';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import TimeField from '../TimeField/TimeField';
import CardFooter from '../Card/CardFooter';
import CardHeader from '../Card/CardHeader';
import UserAvatar from '../UserAvatar';
import parse from 'html-react-parser';

export default function Comment({
	uid,
	bodyRendered,
	_createdOnMS,
	_lastEditMS,
}) {
	return (
		<Card>
			<CardHeader>
				<UserAvatar uid={uid} showName />
			</CardHeader>
			<CardBody>{parse(bodyRendered)}</CardBody>
			<CardFooter>
				<i>
					<TimeField datetime={_createdOnMS} />{' '}
				</i>
			</CardFooter>
		</Card>
	);
}
