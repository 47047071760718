import { useEffect, useState } from 'react';

export default function useForceRefresh() {
	const [forceRefresh, setForceRefresh] = useState(false);

	useEffect(() => {
		forceRefresh && setForceRefresh(false);
	}, [forceRefresh]);

	return { forceRefresh, setForceRefresh };
}
