import { useStore } from '../components/useSupercore';
import { SCModules } from '../components/supercore/api';
import { useEffect } from 'react';
import { actionShorthand } from '../actions';

export default function useLoadView(module, setPending, deps = []) {
	const { state, dispatch } = useStore(module);
	const { doLoadPage, doDelete } = actionShorthand({
		dispatch,
		module: SCModules.events,
	});

	useEffect(() => {
		let mounted = true;

		typeof setPending === 'function' && setPending(true);
		doLoadPage().finally(
			() => mounted && typeof setPending === 'function' && setPending(false)
		);

		return () => {
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...deps]);

	return { state, dispatch, doLoadPage, doDelete };
}
