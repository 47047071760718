import { useStore } from '../../components/useSupercore';
import { SCModules } from '../../components/supercore/api';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardTitle from '../../components/CardTitle';
import { Group } from '@material-ui/icons';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import React, { useState } from 'react';
import Lang from '../../variables/i18n';
import { Navigate } from 'react-router';
import UsersTable from './UsersTable';
import UserDetails from '../../components/UserDetails';
import { CreateButton } from '../../components/CommonButtons';
import { actionShorthand } from '../../actions';
import useMountedHook from '../../utils/useMountedHook';

function UsersView() {
	const { state: store, dispatch } = useStore(SCModules.users);
	const [pending, setPending] = useState(false);

	const { doLoadPage } = actionShorthand({
		dispatch,
		module: SCModules.users,
	});

	useMountedHook((mounted) => {
		setPending(true);
		doLoadPage().finally(() => mounted && setPending(false));
	}, []);

	return (
		<Card>
			<CardHeader>
				<CardTitle title={UsersRoute.name} icon={<Group />}>
					<CreateButton />
				</CardTitle>
			</CardHeader>
			<CardBody>
				<UsersTable pending={pending} store={store} />
			</CardBody>
			<CardFooter>
				<span />
			</CardFooter>
		</Card>
	);
}

const UsersRoute = {
	path: 'users',
	name: Lang.users,
	icon: Group,
	children: [
		{ path: '/', element: <UsersView /> },
		{ path: 'create', element: <UserDetails create /> },
		{ path: 'view', element: <Navigate to="../" /> },
		{ path: 'view/:entryId', element: <UserDetails /> },
		// { path: 'view/:orderId/:tab', element: <OrderDetails /> },
	],
};

export default UsersRoute;
