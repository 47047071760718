import SnackbarContent from '../Snackbar/SnackbarContent';
import React from 'react';

const shouldShow = (messages) => {
	if (messages && !Array.isArray(messages)) {
		return true;
	}

	return Array.isArray(messages) && messages.length > 0;
};

export default function Ntf({ messages, ...props }) {
	const status = messages && messages.status ? messages.status : 'warning';
	const show = shouldShow(messages);

	messages =
		typeof messages === 'object' && !Array.isArray(messages)
			? messages.message
			: messages;

	/*
	console.group('ntf')
	console.log(status)
	console.log(show)
	console.log(typeof messages)
	console.log(messages)
	console.groupEnd()
*/

	return !show ? null : (
		<SnackbarContent
			color={status}
			message={
				Array.isArray(messages)
					? messages.map((m) => (
							<React.Fragment key={m}>
								• {m}
								<br />
							</React.Fragment>
					  ))
					: messages
			}
			{...props}
		/>
	);
}
