import { useQuery } from 'react-query';
import { doDelete, doGet, doPost, doPut } from '../supercore/api';
import { useState } from 'react';

export const getRestActions = (module, documentId) => ({
	doDelete: async (docId) => {
		const res = await doDelete({ module, path: docId || documentId });

		return res.status;
	},
	doUpdate: async (form) => {
		const res = await doPut({ module, path: documentId, body: form });
		let jsonResult = {};

		try {
			jsonResult = await res.json();
		} catch (e) {
			console.log(e);
			return;
		}

		return [res.status, jsonResult];
	},
	doCreate: async (form) => {
		const res = await doPost({ module, body: form });
		let jsonResult = {};

		try {
			jsonResult = await res.json();
		} catch (e) {
			console.log(e);
			return;
		}

		return [res.status, jsonResult];
	},
});

export const useQueryMultiple = (
	module,
	setPending,
	cacheBuster,
	options = {}
) => {
	const { isLoading, isFetching, data } = useQuery(
		module,
		() =>
			doGet({ module })
				.then((res) => (res.status === 200 ? res.json() : {}))
				.then((dt) => dt.data || []),
		{
			placeholderData: [],
			refetchInterval: 10 * 60 * 1000, //10 mins
			refetchOnReconnect: true,
			refetchOnWindowFocus: true,
			queryKeyHashFn: (queryKey) => `${queryKey}${cacheBuster}`,
			onSettled: () => typeof setPending === 'function' && setPending(false),
			...options,
		}
	);

	return [data, isLoading || isFetching];
};

export const generateOnSettled =
	(setForm, setPending, forceRefresh) => (data, e) => {
		typeof setForm === 'function' && setForm(data);
		typeof setPending === 'function' && setPending(false);
		typeof forceRefresh === 'function' && forceRefresh();
	};

export const useQueryDocument = (
	module,
	documentId,
	onSettled = () => {},
	options = {}
) => {
	const [ntf, setNtf] = useState(undefined);
	const { doDelete, doUpdate, doCreate } = getRestActions(module, documentId);
	const { isLoading, isFetching, data } = useQuery(
		module,
		() =>
			doGet({ module, path: documentId }).then((res) =>
				res.status === 200 ? res.json() : {}
			),
		{
			placeholderData: {},
			queryKeyHashFn: (queryKey) => `${queryKey}${documentId}`,
			refetchOnMount: true,
			refetchInterval: false,
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
			onSettled,
			...options,
		}
	);

	return [
		data,
		isLoading || isFetching,
		doCreate,
		doUpdate,
		doDelete,
		ntf,
		setNtf,
	];
};
