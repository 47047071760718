import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Lang from '../../variables/i18n';
import Fab from '@material-ui/core/Fab';
import {
	Add,
	ArrowBack,
	ArrowLeft,
	ArrowUpward,
	Assignment,
	ImageOutlined,
	PlaylistAdd,
	Save,
} from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';

export const SaveButton = ({
	title = Lang.save,
	onClick,
	onPending,
	disabled = false,
}) => (
	<PendingButton
		onPending={onPending}
		icon={<Save />}
		title={title}
		onClick={onClick}
		disabled={disabled}
	/>
);

export const CancelButton = ({
	label = Lang.cancel,
	title = Lang.back,
	onClick,
	disabled = false,
	icon = true,
}) => {
	const navigate = useNavigate();
	return (
		<Tooltip title={title}>
			<span>
				<Button
					disabled={disabled}
					onClick={(e) => (onClick && onClick(e)) || navigate('../')}
				>
					{icon ? <ArrowLeft /> : null} {label}
				</Button>
			</span>
		</Tooltip>
	);
};

export const CreateButton = ({
	title = Lang.create,
	onClick,
	icon,
	isValid,
	onPending,
	disabled = false,
}) => {
	const navigate = useNavigate();
	return (
		<PendingButton
			icon={icon ? icon : <Add />}
			badgeIcon={icon ? <Add /> : null}
			disabled={disabled}
			title={title}
			label={title}
			color="primary"
			isValid={isValid}
			onClick={(e) => (onClick ? onClick(e) : navigate('create'))}
			onPending={onPending}
			disablePending
		/>
	);
};

export const BackButton = () => {
	const navigate = useNavigate();

	return (
		<Tooltip title={Lang.back}>
			<span>
				<Button onClick={() => navigate('../')}>
					<ArrowBack /> {Lang.back}
				</Button>
			</span>
		</Tooltip>
	);
};

export const AddTaskButton = ({ onClick }) => (
	<Tooltip title="Προσθήκη εργασίας">
		<span>
			<Fab size="small" onClick={onClick}>
				<PlaylistAdd />
			</Fab>
		</span>
	</Tooltip>
);

export const AddImageButton = ({ onClick }) => (
	<PendingButton
		title={Lang.add}
		badgeIcon={<Add />}
		icon={<ImageOutlined />}
		size={'small'}
		onClick={onClick}
	/>
);

export const CopyToClipboardButton = ({
	tooltipLabel = Lang.copyToClipboard,
	onClick,
	...props
}) => (
	<Tooltip title={tooltipLabel}>
		<span>
			<IconButton onClick={onClick} {...props}>
				<Assignment />
			</IconButton>
		</span>
	</Tooltip>
);

export const CopyFromAboveButton = ({
	tooltipLabel = 'Αντιγραφή απο την πάνω λίστα',
	onClick,
	...props
}) => (
	<Tooltip title={tooltipLabel}>
		<span>
			<IconButton onClick={onClick} {...props}>
				<Badge
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					badgeContent={<ArrowUpward />}
				>
					<Assignment />
				</Badge>
			</IconButton>
		</span>
	</Tooltip>
);

export const PendingButton = ({
	onClick,
	size = 'large',
	isValid = true,
	disablePending = false,
	onPending,
	disabled = false,
	...props
}) => {
	const [pending, setPending] = useState(false);

	const onClickHandler = async (e) => {
		!disablePending && setPending(true);
		try {
			onPending && onPending(true);
			return await onClick(e);
		} finally {
			!disablePending && setPending(false);
			onPending && onPending(false);
		}
	};

	return (
		<CustomFab
			loadingIcon={<CircularProgress size={48} />}
			{...props}
			onClickHandler={onClickHandler}
			pending={pending}
			size={size}
			disabled={disabled}
			isValid={isValid}
		/>
	);
};

export const CustomFab = ({
	title,
	size,
	color,
	disabled,
	isValid = true,
	badgeIcon,
	onClickHandler,
	label,
	ariaLabel,
	loadingIcon,
	icon,
	pending,
}) => (
	<Tooltip title={title}>
		<span>
			<Fab
				size={size}
				color={color}
				aria-label={ariaLabel || label}
				onClick={onClickHandler}
				disabled={disabled || pending || !isValid}
			>
				<Badge
					badgeContent={badgeIcon}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
				>
					{pending ? loadingIcon : icon}
				</Badge>
			</Fab>
		</span>
	</Tooltip>
);

CreateButton.propTypes = {
	onClick: PropTypes.func,
	title: PropTypes.string,
};

PendingButton.propTypes = {
	icon: PropTypes.node.isRequired,
	loadingIcon: PropTypes.node,
	title: PropTypes.string.isRequired,
	label: PropTypes.string,
	color: PropTypes.string,
	onClick: PropTypes.func,
	isValid: PropTypes.bool,
};
