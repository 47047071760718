import React from 'react';
import PropTypes from 'prop-types';
import CardBody from '../Card/CardBody';
import Card from '../Card/Card';
import Typography from '@material-ui/core/Typography';
import useSupercore, { asArray } from '../useSupercore';
import { SCModules } from '../supercore/api';
import Comment from '../Comment';
import CommentEditor from '../CommentEditor';

export default function GenericComments({ collection, id }) {
	const {
		store = {},
		handleDelete,
		handleCreate,
		handleSave,
	} = useSupercore({
		supercoreApiName: SCModules.content,
		entryId: `parentId/${id}`,
	});

	const comments = asArray(store)
		.filter(
			({ parentId, _isDeleted, _isArchived }) =>
				parentId === id && !_isDeleted && !_isArchived
		)
		.sort((a, b) => b._lastEditMS._seconds - a._lastEditMS._seconds);

	return (
		<Card>
			<CardBody>
				{!comments.length ? (
					<Typography align={'center'} variant="body2">
						No comments
					</Typography>
				) : (
					comments.map(({ id, _createdBy, _lastEditMS, ...props }) => (
						<Comment
							key={id + _lastEditMS._seconds}
							id={id}
							uid={_createdBy}
							_lastEditMS={_lastEditMS}
							{...props}
						/>
					))
				)}
				<CommentEditor
					collection={collection}
					topicId={id}
					handleCreate={handleCreate}
					handleSave={handleSave}
					handleDelete={handleDelete}
				/>
			</CardBody>
		</Card>
	);
}

GenericComments.propTypes = {
	id: PropTypes.string.isRequired,
	collection: PropTypes.string.isRequired,
};
