import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addLocale(en);

export default function getTimeFormatted(datetime) {
	const timeAgo = new TimeAgo('el-GR');
	// console.log(timeAgo.format(datetime));
	return timeAgo.format(datetime);
}
