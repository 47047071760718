import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

/**
 * @return {null}
 */

export default function EmptyState({ pending, empty, error }) {
	return pending || empty || error ? (
		<Card>
			<CardBody>
				{pending ? (
					<CircularProgress />
				) : (
					<Typography align="center" gutterBottom variant="body2">
						{empty && 'No results'}
						{error && 'An error has occurred'}
					</Typography>
				)}
			</CardBody>
		</Card>
	) : null;
}

EmptyState.propTypes = {
	pending: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	empty: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};
