import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Lang from '../../variables/i18n';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import UserAvatar from '../UserAvatar';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from '@material-ui/core';
import { actionShorthand } from '../../actions';
import { SCModules } from '../supercore/api';
import { useStore } from '../useSupercore';

export default function PasswordResetModal({
	open = false,
	title = 'Επαναφορά κωδικού',
	uid,
	onClose,
}) {
	const [pending, setPending] = useState(false);
	const { dispatch } = useStore(SCModules.passwordReset);
	const { doCreate } = actionShorthand({
		dispatch,
		module: SCModules.passwordReset,
	});

	const doOnClick = async () => {
		try {
			setPending(true);
			await doCreate({ uid });
			onClose();
		} catch (e) {
			console.error(e);
		} finally {
			setPending(false);
		}
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle id="password-reset-dialog">{title}</DialogTitle>
			<DialogContent>
				<p>Επαναφορά κωδικού πρόσβασης για αυτό τον χρήστη;</p>
				<UserAvatar uid={uid} showName />
				<p>
					<i>Τι θα γίνει ακριβώς:</i>
					<ul>
						<li>Ο χρήστης θα λάβει email με ενα προσωρινό κωδικό πρόσβασης</li>
						<li>Ο κωδικός πρόσβασης που είχε μέχρι τώρα θα πάψει να ισχύει</li>
						<li>
							Όταν ο χρήστης μπει με τον προσωρινό κωδικό, θα πρέπει να τον
							αλλάξει
						</li>
					</ul>
				</p>
				<Accordion>
					<AccordionSummary>Άν κάτι παει στραβά</AccordionSummary>
					<AccordionDetails>
						<div>
							<p>
								Η αποστολή email γίνεται μεσω{' '}
								<a href={'https://sendgrid.com'}>Sendgrid</a>. Άν δε σταλεί το
								email ελέγξτε τα εξής:
							</p>
							<p>
								<ul>
									<li>
										Βεβαιωθείτε οτι το email δεν εχει μπει στα σπαμ (idiot
										check)
									</li>
									<li>
										Βεβαιωθείτε οτι το σύστημα εχει στείλει το email. Στη βάση
										(Firestore) ελέγξτε τη διαδρομή <tt>sc-email-events/*</tt>.
										Μόλις βρείτε αυτό που ψάχνετε, δείτε αν η τιμή{' '}
										<tt>status</tt> είναι <tt>pending</tt> ή <tt>error</tt>
									</li>
									<li>
										Άν χρειαστεί να ανανεώσετε το <tt>apiKey</tt> απο το
										Sendgrid, αυτό γίνεται μέσω της βάσης (Firestore) στη
										διαδρομή <tt>sc-sys/sendgrid-config</tt>.
									</li>
								</ul>
							</p>
						</div>
					</AccordionDetails>
				</Accordion>
			</DialogContent>
			<DialogActions>
				<Button disabled={pending} onClick={onClose} autoFocus>
					{Lang.cancel}
				</Button>
				<Button disabled={pending} onClick={doOnClick} color="primary">
					{pending && <CircularProgress size={16} />}
					Επαναφορά κωδικού
				</Button>
			</DialogActions>
		</Dialog>
	);
}

PasswordResetModal.propTypes = {
	onClose: PropTypes.func,
	open: PropTypes.bool,
	title: PropTypes.string,
	uid: PropTypes.string.isRequired,
};
