import React from 'react';
import Chip from '@material-ui/core/Chip';
import Lang from '../../variables/i18n';
import {
	AccountBalance,
	AssignmentTurnedIn,
	Check,
	Create,
	CreditCard,
	Delete,
	Edit,
	Euro,
	Event,
	LocalAtm,
	ShoppingBasket,
} from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
}));

export const ChipContainer = ({ show, form, children, ...props }) => {
	const classes = useStyles();
	return show ? (
		<div className={classes.root} {...props}>
			{children}
		</div>
	) : null;
};

export const OrderCompleteChip = ({ isComplete }) =>
	isComplete ? (
		<Chip
			variant={'outlined'}
			color={'primary'}
			icon={<Check />}
			label={'Ολοκληρώθηκε'}
		/>
	) : null;

export const EventsCountChip = ({ eventsCount }) => (
	<Chip
		icon={<Event />}
		variant={'outlined'}
		color={'primary'}
		label={`${Lang.events}: ${eventsCount}`}
	/>
);

export const OrdersCountChip = ({ ordersCount }) => (
	<Chip
		icon={<ShoppingBasket />}
		variant={'outlined'}
		color={'primary'}
		label={`${Lang.orders}: ${ordersCount}`}
	/>
);

export const PaymentChip = ({ isPaid, amountPaid = 0, price = 0 }) => (
	<Chip
		variant={'outlined'}
		color={isPaid ? 'primary' : 'secondary'}
		icon={<Euro />}
		label={isPaid ? 'Εξωφλήθη' : `Υπόλοιπο ${price - amountPaid}`}
	/>
);

export const OrderTasksChip = ({ isTasksDone }) => (
	<Chip
		variant={'outlined'}
		icon={<AssignmentTurnedIn />}
		label={isTasksDone ? 'Εργασίες: Ολοκληρώθηκαν' : 'Εργασίες: Εκκρεμούν'}
	/>
);

export const DeletedChip = ({ show }) =>
	!show ? null : (
		<Chip
			variant={'outlined'}
			color={'secondary'}
			icon={<Delete />}
			label={'Έχει διαγραφεί'}
		/>
	);

export const CashChip = ({ show = true }) =>
	!show ? null : (
		<Chip variant={'outlined'} icon={<LocalAtm />} label={Lang.txSource.cash} />
	);

export const BankChip = ({ show = true }) =>
	!show ? null : (
		<Chip
			variant={'outlined'}
			icon={<AccountBalance />}
			label={Lang.txSource.bank}
		/>
	);

export const CardChip = ({ show = true }) =>
	!show ? null : (
		<Chip
			variant={'outlined'}
			icon={<CreditCard />}
			label={Lang.txSource.card}
		/>
	);

export const CreateActionChip = ({ show = true, variant = 'outlined' }) =>
	!show ? null : (
		<Chip
			variant={variant}
			icon={<Create />}
			label={Lang.create}
			// color={'success'}
		/>
	);

export const EditActionChip = ({ show = true, variant = 'outlined' }) =>
	!show ? null : (
		<Chip
			variant={variant}
			icon={<Edit />}
			label={Lang.edit}
			color={'primary'}
		/>
	);

export const DeleteActionChip = ({ show = true, variant = 'outlined' }) =>
	!show ? null : (
		<Chip
			variant={variant}
			icon={<Delete />}
			label={Lang.delete}
			color={'error'}
		/>
	);
