import React, { useState } from 'react';
import TimeField from '../../components/TimeField/TimeField';
import Lang from '../../variables/i18n';
import Chip from '@material-ui/core/Chip';
import { asUserArray } from '../../components/useSupercore';
import CustomTable from '../../components/Table/Table';
import GenericActions from '../../components/GenericActions';
import LockOpen from '@material-ui/icons/LockOpen';
import UserAvatar from '../../components/UserAvatar';
import PasswordResetModal from '../../components/PasswordResetModal';

export default function UsersTable({ pending, store = {} }) {
	const [passwordResetUid, setPasswordResetUid] = useState(undefined);
	const usersArray = asUserArray(store)
		.sort((a, b) => a.displayName.localeCompare(b.displayName))
		.map(({ uid, displayName, email, createdAt, disabled }) => [
			<UserAvatar uid={uid} />,
			displayName,
			email,
			<TimeField datetime={createdAt} />,
			<Chip
				label={disabled ? Lang.inactive : Lang.active}
				variant="outlined"
				color={disabled ? 'default' : 'primary'}
			/>,
			<GenericActions
				id={uid}
				view={'users'}
				actions={[
					{
						label: 'Επαναφορά κωδικού',
						Icon: LockOpen,
						onClick: () => setPasswordResetUid(uid),
					},
				]}
			/>,
		])
		.reduce((acc, curr) => acc.push(curr) && acc, []);

	return (
		<>
			<CustomTable
				tableHead={[
					'',
					Lang.displayName,
					Lang.email,
					Lang.memberSince,
					Lang.status,
					'',
				]}
				tableData={usersArray}
				pending={pending}
				empty={!pending && usersArray.length === 0}
			/>
			<PasswordResetModal
				open={Boolean(passwordResetUid)}
				uid={passwordResetUid}
				onClose={() => setPasswordResetUid(undefined)}
			/>
		</>
	);
}
