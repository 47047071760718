import React from 'react';
import useSupercore, { asUserArray } from '../useSupercore';
import { SCModules } from '../supercore/api';
import ComboBox from '../ComboBox';
import UserAvatar from '../UserAvatar';
import Lang from '../../variables/i18n';
import PropTypes from 'prop-types';

export default function UserPicker({
	id,
	onChange,
	required = false,
	disabled = false,
	value,
	label = Lang.user,
	fullWidth = true,
}) {
	const { store, status } = useSupercore({ supercoreApiName: SCModules.users });
	const users = asUserArray(store)
		.filter((user) => !user.disabled && !user.deleted)
		.sort((a, b) => (a.displayName || '').localeCompare(b.displayName || ''));

	return (
		<ComboBox
			id={id}
			value={value}
			options={users}
			label={label}
			required={required}
			disabled={disabled || status === 'pending'}
			loading={status === 'pending'}
			onChange={(e, val) =>
				onChange({ target: { value: val ? val.uid : undefined } })
			}
			renderOption={(o) => (
				<UserAvatar
					onClick={(e, uid) => onChange({ target: { value: uid } })}
					uid={o.uid}
					showName
				/>
			)}
			getOptionLabel={(o) => (o || {}).displayName || ''}
			// renderTags={(v,props)=> v.map(o=><UserAvatar uid={o.uid} showName />)}
			autoSelect
			fullWidth={fullWidth}
			blurOnSelect
		/>
	);
}

UserPicker.propTypes = {
	id: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
	fullWidth: PropTypes.bool,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
};
