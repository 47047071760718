import GenericForm from '../GenericForm';
import Lang from '../../variables/i18n';
import React from 'react';
import * as yup from 'yup';
import { Dimension } from '../../variables/general';
import ItemsList from '../ItemsList';
import FilePriceBrief from '../FilePriceBrief';
import schoolEventPanel from '../SchoolEventPanel';
import { genericAddressFields } from '../GenericAddressForm';
import ComplementaryLink from '../ComplementaryLink';
import OrderTask from '../OrderTask';

export default function OrderForm({
	create,
	form,
	setForm,
	setIsValid,
	dispatch,
}) {
	const photoListId = 'photosList';

	const photoListOnClick = () => {
		const el = document.getElementById(photoListId);
		const val = (el.value || '').split(/\s+/).filter((s) => s.length);

		if (val.length === 0) {
			return;
		}

		setForm({
			...form,
			[photoListId]: [...new Set([...(form[photoListId] || []), ...val])],
		});

		el.value = '';
	};

	const photoListOnDelete = (item) => {
		setForm({
			...form,
			[photoListId]: [...form[photoListId].filter((t) => t !== item)],
		});
	};

	const {
		photosList,
		eventId,
		price,
		isDelivery,
		deliveryPrice,
		complementaryOf,
	} = form;

	const addressFields = genericAddressFields.map((field) => ({
		...field,
		required: isDelivery,
	}));

	return (
		<React.Fragment>
			<GenericForm
				setIsValid={setIsValid}
				fields={[
					{
						id: 'isDelivery',
						type: 'checkbox',
						label: 'Αντικαταβολή',
						size: Dimension.formItemOneThird,
					},
					{
						id: 'isEleven',
						type: 'checkbox',
						label: 'Έντεκα',
						size: Dimension.formItemOneThird,
					},
					{
						id: 'isDeficient',
						type: 'checkbox',
						label: 'Έχει ελλείψεις',
						size: Dimension.formItemOneThird,
					},
					...[
						{
							id: 'complementaryOf',
							label: 'Συμπληρωματική',
							yup: yup.string(),
						},
						{
							id: 'complementaryLink',
							label: 'Πήγαινε στη συμπληρωματική',
							renderElement: () => (
								<ComplementaryLink
									complementaryOf={complementaryOf}
									dispatch={dispatch}
									create={create}
								/>
							),
						},
					],
					{
						id: 'fl1',
						type: 'formLabel',
						label: 'Στοιχεία πελάτη',
					},
					{
						id: 'lastName',
						label: 'Επώνυμο',
						yup: yup.string().required(),
						required: true,
					},
					{
						id: 'firstName',
						label: 'Όνομα',
						yup: yup.string(),
						required: false,
					},
					{
						id: 'tel',
						label: 'Τηλέφωνο',
						yup: yup.string().required(),
						required: true,
					},
					{
						id: 'email',
						label: 'e-mail',
						type: 'email',
						yup: yup.string().email(),
					},
					...addressFields,
					{
						id: 'deliveryPrice',
						label: 'Κόστος αντικαταβολής',
						type: 'number',
						disabled: !isDelivery,
						required: isDelivery,
					},
					{
						id: 'trackingId',
						label: 'Tracking number',
						disabled: !isDelivery,
						size: Dimension.formItemFullWidth,
					},
					...OrderTask(
						'taskPostedByMail',
						'Απεστάλη με ταχυδρομείο',
						form,
						create,
						false,
						!!form['isDelivery']
					),
					...schoolEventPanel({ form, setForm }),
					{
						id: 'filePriceCalculator',
						size: Dimension.formItemFullWidth,
						renderElement: () => (
							<FilePriceBrief
								filesCount={(photosList || []).length}
								eventId={eventId}
								orderPrice={price}
								deliveryPrice={deliveryPrice}
								isDelivery={isDelivery}
							/>
						),
						type: 'renderElement',
					},
					{
						id: 'price',
						label: `${Lang.price} €`,
						type: 'number',
						yup: yup.number().min(0).required(),
						required: true,
					},
					{
						id: 'orderDate',
						label: Lang.ordersView.paymentDetails.creationDate,
						type: 'datepicker',
						disabled: !create,
					},
					{
						id: 'photosList',
						size: Dimension.formItemFullWidth,
						renderElement: () => (
							<ItemsList
								id={photoListId}
								formState={form}
								setFormState={setForm}
								labelText={'Αριθμός φωτογραφίας'}
								emptyText={'Οι φωτογραφίες που προστίθενται θα φαίνονται εδώ'}
								onClick={photoListOnClick}
								onDelete={photoListOnDelete}
								copyToClipboard
							/>
						),
					},
					{
						id: 'printComments',
						size: Dimension.formItemFullWidth,
						type: 'textarea',
						label: 'Σχόλια',
					},
					...OrderTask(
						'taskPrePrint',
						'Παραλαβή: Ψηφιακά αρχεία / Photoshop / CD / Τύπωμα',
						form,
						create
					),
					...OrderTask(
						'taskPrePrintDone',
						'Ψηφιακά αρχεία / Photoshop / CD / Τύπωμα - ΟΚ',
						form,
						create
					),
				]}
				formState={form}
				setFormState={setForm}
			/>
		</React.Fragment>
	);
}
