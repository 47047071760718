import { Add, Euro, ImportContacts } from '@material-ui/icons';
import React from 'react';
import Badge from '@material-ui/core/Badge';

const Icons = {
	photobook: ImportContacts,
};

export const AddMoneyIcon = () => (
	<Badge
		badgeContent={<Add />}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
	>
		<Euro />
	</Badge>
);
export default Icons;
