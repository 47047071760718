import { useEffect } from 'react';

export default function useMountedHook(fn, deps = []) {
	useEffect(() => {
		let mounted = true;

		mounted && fn(mounted);

		return () => {
			mounted = false;
		};
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...deps]);
}
