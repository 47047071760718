import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import { Dimension } from '../../variables/general';
import GenericAdditionalInfo from '../GenericAdditionalInfo';
import CardHeader from '../Card/CardHeader';
import Card from '../Card/Card';
import CardFooter from '../Card/CardFooter';
import { CancelButton, CreateButton, SaveButton } from '../CommonButtons';
import CardBody from '../Card/CardBody';
import { SCModules } from '../supercore/api';
import GenericForm from '../GenericForm';
import { Event } from '@material-ui/icons';
import SchoolSelect from '../SchoolSelect';
import Lang from '../../variables/i18n';
import * as yup from 'yup';
import GenericBackdrop from '../GenericBackdrop';
import CardTitle from '../CardTitle';
import { concatSchool, parseDate } from '../../utils';
import { ChipContainer, OrdersCountChip } from '../CommonChips';
import { useQueryDocument } from '../ReactQueryUtils';
import Ntf from '../Ntf';

const InitialData = {
	date: new Date(),
};

const parseIncomingData = ({ date, otherStudios, ...rest } = {}) => ({
	date: new Date(date && date._seconds ? date._seconds * 1000 : date),
	otherStudios: [...new Set(otherStudios)],
	...rest,
});

export default function EventDetails({ create = false }) {
	const autocompleteId = 'schoolId';
	const { eventId } = useParams();
	const [isValid, setIsValid] = useState(false);
	const [pendingState, setPending] = useState(false);
	const [ntf, setNtf] = useState(undefined);
	const [form, setForm] = useState(InitialData);
	const navigate = useNavigate();

	// eslint-disable-next-line
	const [result, isLoading, handleCreate, handleSave, handleDelete] =
		useQueryDocument(
			SCModules.events,
			eventId,
			(data, e) => {
				if (e) {
					setNtf(e.message);
				}
				setForm(parseIncomingData(data));
			},
			{ enable: !create, placeholderData: InitialData }
		);
	const pending = pendingState || isLoading;

	const { date, ordersCount } = form;

	return (
		<GridContainer>
			<GridItem {...Dimension.md}>
				<Card>
					<CardHeader>
						<CardTitle
							id={eventId}
							create={create}
							icon={<Event />}
							handleDelete={handleDelete}
							createTitle={Lang.eventDetails.createTitle}
							editTitle={`${Lang.eventDetails.title} ${concatSchool(
								form
							)} ${parseDate(date)}`}
						/>
						<ChipContainer show={!create}>
							<OrdersCountChip ordersCount={ordersCount} />
						</ChipContainer>
					</CardHeader>
					<CardBody>
						<Ntf messages={ntf} close onClose={() => setNtf(undefined)} />
						<GenericBackdrop open={pending} />
						<GenericForm
							setFormState={setForm}
							formState={form}
							setIsValid={setIsValid}
							fields={[
								{
									id: 'l1',
									type: 'formLabel',
									label: Lang.eventDetails.labelSchoolAndDate,
								},
								{
									id: 'date',
									type: 'datepicker',
									label: 'Ημερομηνία Ορκομωσίας',
									size: Dimension.formItemOneThird,
									disabled: !create,
									required: true,
								},
								{
									id: autocompleteId,
									size: Dimension.formItemTwoThirds,
									yup: yup.string().required(),
									renderElement: () => (
										<SchoolSelect
											disabled={!create}
											ignoreNoEvents={true}
											id={autocompleteId}
											value={form[autocompleteId]}
											onChange={({ target }) => {
												console.log(target.value);
												setForm({ ...form, [autocompleteId]: target.value });
											}}
										/>
									),
								},
								{
									id: 'sl1',
									type: 'formLabel',
									label: Lang.eventDetails.labelEventStatistics,
								},
								{
									id: 'numberPeople',
									label: Lang.eventDetails.numberPeople,
									placeholder: Lang.eventDetails.numberPeoplePlaceholder,
									yup: yup.number().min(0),
									required: true,
									type: 'number',
								},
								{
									id: 'numberPhotos',
									label: Lang.eventDetails.numberPhotos,
									placeholder: Lang.eventDetails.numberPhotosPlaceholder,
									yup: yup.number().min(0),
									required: true,
									type: 'number',
								},
								{
									id: 'filePrice',
									label: Lang.eventDetails.filePrice,
									placeholder: Lang.eventDetails.filePriceExpl,
									yup: yup.number().min(0),
									required: true,
									type: 'number',
								},
								{
									id: 'otherStudios',
									label: Lang.eventDetails.otherStudios,
									placeholder: Lang.eventDetails.otherStudiosPlaceholder,
									type: 'textarea',
								},
							]}
						/>
					</CardBody>
					<CardFooter>
						<CancelButton />
						{create ? (
							<CreateButton
								icon={<Event />}
								isValid={isValid}
								disabled={pending}
								onPending={setPending}
								onClick={async () => {
									try {
										setPending(true);
										const [status, result = {}] = await handleCreate(form);
										const { data = {} } = result;
										const { id } = data;

										if (status === 201) {
											navigate(`/admin/events/view/${id}`);
										}
										window.scrollTo({ top: 0, behavior: 'smooth' });
										setNtf(result);
									} finally {
										setPending(false);
									}
								}}
							/>
						) : (
							<SaveButton
								disabled={pending}
								isValid={isValid}
								onPending={setPending}
								onClick={async () => {
									try {
										setPending(true);
										const [status, result] = await handleSave(form);
										setNtf(
											status === 200
												? { message: 'Ανανεώθηκε επιτυχώς', status: 'info' }
												: result
										);
									} finally {
										setPending(false);
									}
								}}
							/>
						)}
					</CardFooter>
				</Card>
			</GridItem>
			<GridItem {...Dimension.md}>
				<GenericAdditionalInfo
					show={!create}
					collection={'events'}
					id={eventId}
				/>
			</GridItem>
		</GridContainer>
	);
}
