import React, { useContext } from 'react';
import SnackbarContent from '../components/Snackbar/SnackbarContent';
import { AppContext } from '../store/store';

const LayoutNotification = () => {
	const { error } = useContext(AppContext);
	return (error && <SnackbarContent message={error} />) || null;
};

export const validateEmail = (email) => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

export const getInitials = ({ displayName, firstName, lastName }) => {
	if (firstName && lastName) {
		return `${firstName[0]} ${lastName[0]}`;
	}

	if (displayName && displayName.indexOf(' ') > 0) {
		const [first, last] = displayName.split(' ', 1);
		return getInitials({ firstName: first, lastName: last });
	}

	return displayName && displayName.replace(/[aeiou]/gi, '').substr(0, 2);
};

export default LayoutNotification;
