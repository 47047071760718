import React from 'react';
import Admin from './layouts/Admin';
import Login from './layouts/Login';
import { Navigate } from 'react-router';
import { SystemProperties } from './variables/sysSettings';
import VaultRoute from './views/VaultView/VaultView';
import OrdersRoute from './views/Orders/OrdersView';
import EventsRoute from './views/EventsView/EventsView';
import SchoolsRoute from './views/SchoolsView/SchoolsView';
import UsersRoute from './views/Users/UsersView';
import ResetPassword from './layouts/ResetPassword';

const dashboardRoutes = [
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: '/resetPassword',
		element: <ResetPassword />,
	},
	{
		path: SystemProperties.dashboardRootPath.val,
		element: <Admin />,
		children: [
			OrdersRoute,
			VaultRoute,
			EventsRoute,
			SchoolsRoute,
			UsersRoute,
			// ContentRoute,
			// SystemRoute,
		],
	},
	{
		path: `${SystemProperties.dashboardRootPath.val}/dashboard`,
		element: (
			<Navigate
				to={`${SystemProperties.dashboardRootPath.val}/${OrdersRoute.path}`}
			/>
		),
	},
	{
		path: '/',
		element: (
			<Navigate
				to={`${SystemProperties.dashboardRootPath.val}/${OrdersRoute.path}`}
			/>
		),
	},
];

export default dashboardRoutes;
