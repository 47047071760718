import React from 'react';
import UserAvatar from '../UserAvatar';
import TimeField from '../TimeField/TimeField';
import PropTypes from 'prop-types';

export default function OrderTaskAuthor({ form, taskId }) {
	const uid = form[`${taskId}By`];
	const changedAt = form[`${taskId}At`];

	// console.log(`OrderTask ${taskId} ${uid} ${JSON.stringify(changedAt)}`)

	return !uid ? null : (
		<>
			<UserAvatar uid={uid} showName /> <TimeField datetime={changedAt} />{' '}
		</>
	);
}

OrderTaskAuthor.propTypes = {
	form: PropTypes.object.isRequired,
	taskId: PropTypes.string.isRequired,
};
