import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Currency, { GrayText } from '../Typography/Currency';
import TableContainer from '@material-ui/core/TableContainer';
import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import { SCModules } from '../supercore/api';
import { useStore } from '../useSupercore';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

export default function FilePriceBrief({
	eventId,
	filesCount = 0,
	deliveryPrice = 0,
	orderPrice = 0,
	isDelivery = false,
}) {
	const { state } = useStore();
	const event = (state[SCModules.events] || {})[eventId] || {};
	const { filePrice = 0 } = event;

	const suggestedPrice =
		filePrice * filesCount +
		(isDelivery && typeof deliveryPrice === 'number' ? deliveryPrice : 0);

	const discountPrice = suggestedPrice - orderPrice;

	return (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMore />}>
				<FormLabel style={{ width: '100%' }}>
					Ενδεικτική τιμή παραγγελίας:
					<Currency style={{ float: 'right' }} value={suggestedPrice} />
				</FormLabel>
			</AccordionSummary>
			<AccordionDetails>
				<TableContainer component={Paper}>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>Τιμή αρχείου ορκομωσίας</TableCell>
								<TableCell align={'right'}>
									<Currency value={filePrice} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Τιμή αντικαταβολής</TableCell>
								<TableCell align={'right'}>
									<Currency value={isDelivery && deliveryPrice} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Αρχεία</TableCell>
								<TableCell align={'right'}>{filesCount}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<b>Σύνολο</b>
								</TableCell>
								<TableCell align={'right'}>
									<b>
										<Currency value={suggestedPrice} />
									</b>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									Έκπτωση <GrayText>(ενδεικτική τιμή - τελική τιμή)</GrayText>
								</TableCell>
								<TableCell align={'right'}>
									<GrayText>{discountPrice > 0 ? 'Έκπτωση' : ''} </GrayText>
									<Currency value={Math.max(0, discountPrice)} />
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</AccordionDetails>
		</Accordion>
	);
}

FilePriceBrief.propTypes = {
	eventId: PropTypes.string,
	filesCount: PropTypes.number.isRequired,
	orderPrice: PropTypes.number,
	deliveryPrice: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};
