import { useCallback, useEffect, useState } from 'react';
import { doDelete, doGet, doPost, doPut } from '../api';

export const capitalise = (s) =>
	typeof s !== 'string' ? '' : s.charAt(0).toUpperCase() + s.slice(1);

export const useAsync = (asyncFunction, immediate = true) => {
	const [status, setStatus] = useState('idle');
	const [value, setValue] = useState(null);
	const [error, setError] = useState(null);

	// The execute function wraps asyncFunction and
	// handles setting state for pending, value, and error.
	// useCallback ensures the below useEffect is not called
	// on every render, but only if asyncFunction changes.
	const execute = useCallback(() => {
		setStatus('pending');
		setValue(null);
		setError(null);

		return asyncFunction()
			.then((response) => {
				setValue(response);
				setStatus('success');
			})
			.catch((error) => {
				setError(error);
				setStatus('error');
			});
	}, [asyncFunction]);

	// Call execute if we want to fire it right away.
	// Otherwise execute can be called later, such as
	// in an onClick handler.
	useEffect(() => {
		if (immediate) {
			execute();
		}
	}, [execute, immediate]);

	return { execute, status, value, error };
};

/**
 * @typedef SupercoreApi
 * @property create {function}
 * @property update {function}
 * @property delete {function}
 * @property get {function}
 * */

/**
 * Supercore API base
 * @param module
 * @param {*} params
 * @returns {SupercoreApi}
 */
export const SCModuleAPI = ({ module, params = {} }) => ({
	create: (data) =>
		doPost({
			module,
			body: {
				...data,
			},
		}),
	update: (id = params.path, data = params.data) =>
		doPut({ module, path: id, body: { ...data } }),
	delete: (id = params.path) => doDelete({ module, path: id }),
	get: (id = params.path) => doGet({ module, path: id, body: params.data }),
});
