import { firebaseConfig } from '../firebase.config';

const SystemSettingsDefaults = {
	defaultLocale: 'gr',
	allowSelfSignup: false,
};

/**
 * @typedef SystemProperty
 * @type {object}
 * @param {string} desc - Describes the purpose of this property
 * @param {string} val - Value
 */

/**
 * System properties.
 * @type {{dashboardRootPath: {val: string, desc: string}}}
 */
export const SystemProperties = {
	/**
	 * @type SystemProperty
	 */
	baseUrl: {
		description: 'Base URL for Cloud Functions',
		val: `https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net`,
	},
	/**
	 * @type SystemProperty
	 */
	dashboardRootPath: {
		desc: 'Relative URL prefix for all dashboard pages',
		val: '/admin',
	},

	defaultLocale: {
		desc: 'Dashboard locale',
		val: 'el-GR',
	},
};

export default SystemSettingsDefaults;
