import React, { useEffect, useState } from 'react';
import { asArray, useStore } from '../useSupercore';
import { SCModules } from '../supercore/api';
import { actionShorthand } from '../../actions';
import CustomTable from '../Table/Table';
import UserAvatar from '../UserAvatar';
import Lang from '../../variables/i18n';
import { CustomFab } from '../CommonButtons';
import ManualAmountDialog from '../ManualAmountDialog';
import GenericActions from '../GenericActions';
import { CalendarToday, Edit } from '@material-ui/icons';

const headers = ['Ημερομηνία', 'Βάρδια', 'Χρήστης', 'Σχόλια', 'Ποσό', ''];

const getDateFromID = (id) => {
	const [year, month, day] = id.split('-');
	return `${day}/${month}/${year}`;
};

const getShiftFromID = (id) => id.split('-').pop();

export default function ManualAmountsTable() {
	const [entity, setEntity] = useState(undefined);
	const [pending, setPending] = useState(false);
	const { state, dispatch } = useStore(SCModules.manualAmounts);
	const { doLoadPage, doSave } = actionShorthand({
		dispatch,
		module: SCModules.manualAmounts,
	});

	useEffect(() => {
		let mounted = true;

		setPending(true);
		doLoadPage().finally(() => mounted && setPending(false));

		return () => {
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entity]);

	// console.log(Boolean(entity));
	// console.log(entity);

	const entries = asArray(state)
		.sort((a, b) => b.id.localeCompare(a.id))
		.map(({ id, amount = 0, _lastEditBy, notes }) => [
			getDateFromID(id),
			getShiftFromID(id),
			<UserAvatar showName uid={_lastEditBy} />,
			notes,
			`${Lang.currencySymbol.euro} ${amount}`,
			<GenericActions
				id={id}
				actions={[
					{
						label: Lang.edit,
						Icon: Edit,
						onClick: () => {
							// console.log(`fire on ${id}`);
							//TODO this doesn't work due to useEffect above, probably
							setEntity(entries[id]);
						},
					},
				]}
			/>,
		]);

	return (
		<>
			<CustomFab
				title={'Κλείσιμο ταμείου σήμερα'}
				icon={<CalendarToday />}
				onClickHandler={() => {
					setEntity(true);
				}}
			/>
			<CustomTable
				pending={pending}
				empty={!entries || entries.length === 0}
				tableHead={headers}
				tableData={entries}
			/>
			<ManualAmountDialog
				open={Boolean(entity)}
				entity={entity}
				onClose={() => setEntity(undefined)}
				pending={pending}
				doSave={doSave}
			/>
		</>
	);
}
