import React from 'react';
import GenericForm from '../GenericForm';
import { Dimension } from '../../variables/general';
import ItemsList from '../ItemsList';
import { CopyFromAboveButton } from '../CommonButtons';
import OrderTask from '../OrderTask';

/**
 * Add new element to list
 * @param inputId
 * @param formState
 * @param setFormState
 */
const photoListOnClick = (inputId, formState, setFormState) => {
	const el = document.getElementById(inputId);
	const val = (el.value || '').split(/\s+/).filter((s) => s.length);

	if (val.length === 0) {
		return;
	}

	setFormState({
		...formState,
		[inputId]: [...new Set([...(formState[inputId] || []), ...val])],
	});

	el.value = '';
};

const copyListFromAbove = ({ formState, setFormState }) => {
	const photoListId = 'photosList';
	const photoListPhbId = 'photosList_phb';

	setFormState({
		...formState,
		[photoListPhbId]: formState[photoListId] || [],
	});
};

export default function OrderExtrasForm({
	formState,
	setFormState,
	create = false,
}) {
	const { phbSize = 'none' } = formState;

	const fields = [
		{
			id: 'fl2030',
			type: 'formLabel',
			label: '20x30',
		},
		{
			id: 'is2030',
			type: 'checkbox',
			label: 'Έχει 20x30',
			size: Dimension.formItem,
		},
		{
			id: 'extra2030',
			type: 'textarea',
			label: 'Παρατηρήσεις 20x30',
			size: Dimension.formItem,
			display: formState.is2030,
			disabled: !formState.is2030,
		},

		{
			id: 'fl1015',
			type: 'formLabel',
			label: '10x15',
		},
		{
			id: 'is1015',
			type: 'checkbox',
			label: 'Έχει 10x15',
			size: Dimension.formItem,
		},
		{
			id: 'extra1015',
			type: 'textarea',
			label: 'Παρατηρήσεις 10x15',
			size: Dimension.formItem,
			display: formState.is1015,
			disabled: !formState.is1015,
		},

		{
			id: 'fldvd',
			type: 'formLabel',
			label: 'DVD',
		},
		{
			id: 'isDvd',
			type: 'checkbox',
			label: 'Έχει DVD',
			size: Dimension.formItemFullWidth,
		},
		...OrderTask(
			'taskDvd',
			'Εγγραφή DVD',
			formState,
			create,
			false,
			!!formState['isDvd']
		),
		{
			id: 'extradvd',
			type: 'textarea',
			label: 'Παρατηρήσεις DVD',
			display: formState.isDvd,
			disabled: !formState.isDvd,
		},
		{
			id: 'flphb',
			type: 'formLabel',
			label: 'Photobook',
		},
		{
			id: 'phbSize',
			type: 'radio',
			label: 'Μέγεθος',
			options: [
				{
					label: 'Κανένα',
					value: 'none',
					selected: phbSize === 'none',
				},
				{
					label: '20x20 (Μικρό)',
					value: '20x20',
					selected: phbSize === '20x20',
				},
				{
					label: '20x30 (Μεγάλο)',
					value: '20x30',
					selected: phbSize === '20x30',
				},
			],
		},
		{
			id: 'photosList_phb',
			size: Dimension.formItemFullWidth,
			display: formState.phbSize && formState.phbSize !== 'none',
			renderElement: () => (
				<ItemsList
					key={`photosList_phb-${(formState['photosList'] || []).length}`}
					copyToClipboard
					id={'photosList_phb'}
					formState={formState}
					setFormState={setFormState}
					onClick={() =>
						photoListOnClick('photosList_phb', formState, setFormState)
					}
					labelText={'Αριθμός φωτογραφίας για Photobook'}
					emptyText={
						'Αφήστε το κενό αν η λίστα του Photobook είναι ίδια με της παραγγελίας'
					}
					extraButton={
						<CopyFromAboveButton
							disabled={!(formState['photosList'] || []).length}
							onClick={() => copyListFromAbove({ formState, setFormState })}
						/>
					}
				/>
			),
		},
		{
			id: 'extra',
			type: 'textarea',
			label: 'Παρατηρήσεις Photobook',
			placeholder: 'Σχόλια ειδικά για το Photobook',
			display: formState.phbSize && formState.phbSize !== 'none',
			size: Dimension.formItemFullWidth,
		},
		...OrderTask(
			'taskPhbStarted',
			'(photobook) Το ανέλαβε',
			formState,
			create,
			false,
			formState.phbSize && formState.phbSize !== 'none'
		),
		...OrderTask(
			'taskPhbSentPrint',
			'Εστάλη για τύπωμα (photobook)',
			formState,
			create,
			false,
			formState.phbSize && formState.phbSize !== 'none'
		),
		...OrderTask(
			'taskPhbPrintDone',
			'Παραλαβή photobook',
			formState,
			create,
			false,
			formState.phbSize && formState.phbSize !== 'none'
		),
	];

	return (
		<GenericForm
			setFormState={setFormState}
			formState={formState}
			formLabel={'Δώρα'}
			fields={fields}
		/>
	);
}
