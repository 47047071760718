import React from 'react';
// @material-ui/core components
import ProfileWidget from './ProfileWidget';

export default function AdminNavbarLinks() {
	return (
		<div>
			<ProfileWidget />
		</div>
	);
}
