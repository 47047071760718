import React, { useEffect, useState } from 'react';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardFooter from '../Card/CardFooter';
import Lang from '../../variables/i18n';
import { useNavigate, useParams } from 'react-router';
import { BackButton, CreateButton, SaveButton } from '../CommonButtons';
import { useStore } from '../useSupercore';
import { SCModules } from '../supercore/api';
import { ShoppingBasketOutlined, ViewModule } from '@material-ui/icons';
import OrderForm from '../OrderForm';
import CardBody from '../Card/CardBody';
import OrderExtrasForm from '../OrderExtrasForm';
import GenericBackdrop from '../GenericBackdrop';
import CardTitle from '../CardTitle';
import {
	ChipContainer,
	OrderCompleteChip,
	OrderTasksChip,
	PaymentChip,
} from '../CommonChips';
import { AddMoneyIcon } from '../../variables/AppIcons';
import PaymentModal from '../PaymentModal';
import GridContainer from '../Grid/GridContainer';
import { Dimension } from '../../variables/general';
import GridItem from '../Grid/GridItem';
import GenericAdditionalInfo from '../GenericAdditionalInfo';
import { actionShorthand } from '../../actions';
import { formatSerialCount } from '../../utils';
import Ntf from '../Ntf';

const InitialData = {
	orderDate: new Date(),
	isDvd: false,
	is2030: false,
	is1015: false,
	country: 'Ελλάδα',
	phbSize: 'none',
};
const parseIncomingData = ({
	orderDate = {},
	photosList,
	photosList_phb,
	...rest
} = {}) => {
	rest.orderDate = new Date(orderDate._seconds * 1000);
	rest.photosList = [...new Set(photosList)];
	rest.photosList_phb = [...new Set(photosList_phb)];

	return rest;
};

export default function OrderDetails({ create = false }) {
	const [pending, setPending] = useState(false);
	const [forceRefresh, setForceRefresh] = useState(0);
	const { orderId: entryId } = useParams();
	const [txModal, setTxModal] = useState(undefined);
	const [ntfTxt, setNtfTxt] = useState();
	const navigate = useNavigate();
	const { state, dispatch } = useStore();
	const orderDetails = (state[SCModules.orders] || {})[entryId] || {};
	const lastEdit = (orderDetails._lastEditMS || {})._seconds;
	const { doLoadEntry, doSave, doCreate, doDelete } = actionShorthand({
		dispatch,
		module: SCModules.orders,
		id: entryId,
	});

	useEffect(() => {
		let mounted = true;

		if (create) {
			return;
		}

		setPending(true);
		doLoadEntry().finally(() => {
			mounted && setPending(false);
		});

		if (orderDetails) {
			setForm(parseIncomingData(orderDetails));
		}

		return () => {
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entryId, create, forceRefresh, lastEdit]);

	const [form, setForm] = useState({
		...InitialData,
		...(create ? {} : parseIncomingData(orderDetails)),
	});

	const { isPaid, amountPaid, price, _isDeleted, phbSize } = form;

	const handleCreateOrderAndTasks = async () => {
		form.hasPhotobook = phbSize && phbSize !== 'none';

		const { id, fail, error } = await doCreate(form);

		if (fail) {
			setNtfTxt(error);
			return;
		}

		navigate(`/admin/orders/view/${id}`);
	};

	return (
		<GridContainer>
			<GridItem {...Dimension.pageTwoThirds}>
				<Card>
					<GenericBackdrop open={pending} />
					<CardHeader>
						<CardTitle
							id={entryId}
							lastEdit={lastEdit}
							create={create}
							handleDelete={() => {
								setPending(true);
								doDelete().finally(() => {
									setPending(false);
									setForceRefresh(forceRefresh + 1);
								});
							}}
							createTitle={Lang.ordersView.createOrderTitle}
							editTitle={`${Lang.ordersView.viewOrderTitle} ${formatSerialCount(
								form
							)}`}
							icon={<ShoppingBasketOutlined />}
							isDeleted={_isDeleted}
							actions={[
								{
									label: 'Νέα Πληρωμή',
									Icon: AddMoneyIcon,
									onClick: () => setTxModal(entryId),
									disabled: isPaid,
									onComplete: () => setForceRefresh(forceRefresh + 1),
								},
							]}
						/>
						<ChipContainer show={!create}>
							<OrderCompleteChip {...form} />
							<PaymentChip
								isPaid={isPaid}
								amountPaid={amountPaid}
								price={price}
							/>
							<OrderTasksChip {...form} />
						</ChipContainer>
					</CardHeader>
					<CardBody>
						<Ntf messages={ntfTxt} close onClose={() => setNtfTxt(undefined)} />
						<OrderForm
							dispatch={dispatch}
							create={create}
							form={form}
							setForm={setForm}
						/>
						<OrderExtrasForm
							formState={form}
							setFormState={setForm}
							create={create}
						/>
					</CardBody>
					<CardFooter>
						<BackButton />
						{create ? (
							<CreateButton
								icon={<ViewModule />}
								onPending={setPending}
								onClick={() => handleCreateOrderAndTasks(form)}
							/>
						) : (
							<SaveButton
								onPending={setPending}
								disabled={_isDeleted}
								onClick={() => {
									setPending(true);
									doSave(form).finally(() => {
										setForceRefresh(forceRefresh + 1);
										setPending(false);
									});
								}}
							/>
						)}
					</CardFooter>
					<PaymentModal
						open={!!txModal}
						orderId={txModal}
						create
						onClose={() => {
							setForceRefresh(forceRefresh + 1);
							setTxModal(undefined);
						}}
					/>
				</Card>
			</GridItem>
			<GridItem {...Dimension.pageOneThird}>
				{!create && (
					<GenericAdditionalInfo
						show={!create}
						collection={'orders'}
						id={entryId}
					/>
				)}
			</GridItem>
		</GridContainer>
	);
}
