import { useReducer } from 'react';

export const actions = {
	/**
	 * Load info of a specific user
	 */
	LOAD_USER_INFO: 'LOAD_USER_INFO',

	/**
	 * Add data to the state
	 */
	SET_USER_INFO: 'SET_USER_INFO',
};

const initialState = {};

function reducer(state, action) {
	const { type, data } = action;
	let newState = { ...state };

	console.group(`Start: ${type}`);
	console.log('Data');
	console.log(data);
	console.log('State');
	console.log(state);

	// eslint-disable-next-line
	switch (type) {
		case actions.SET_USER_INFO:
			if (!data) {
				console.warn(`Empty data at ${new Error().stack}`);
				console.groupEnd();
				newState = state;
				break;
			}

			const authorsPayload = data.length
				? data
						.map((entry) => ({ [entry.uid]: entry }))
						.reduce((acc, curr) => ({ ...acc, ...curr }), {})
				: { [data.uid]: data };

			newState = {
				...state,
				...authorsPayload,
			};
			break;

		default:
			console.groupEnd();
			throw new Error('No action type defined');
	}

	console.log('New state');
	console.log(newState);
	console.groupEnd();
}

export default function useUsers() {
	const [state, dispatch] = useReducer(reducer, initialState);
	let customDispatch = (action) => {
		if (typeof action === 'function') {
			action(customDispatch);
		} else {
			dispatch(action);
		}
	};
	return [state, customDispatch];
}
